import React, { useEffect, useState, useContext } from "react";

import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
import { pink, green, orange } from "@material-ui/core/colors";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Movepoint from "components/Movepoint/Movepoint.js";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: "5px",
    width: "120px",
  },
  selectEmpty: {
    marginTop: "2",
  },
  searchInput: {
    width: "300px",
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px",
  },
  inputText: {
    width: "100px",
    padding: "5px",
    margin: 0,
    fontize: 14,
  },
  inputLongText: {
    width: "200px",
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    marginRight: "10px",
    height: "30px",
  },
  dangerButton: {
    background: "#d62546",
    color: "#fff",
    marginRight: "10px",
  },
  successAlert: {
    position: "fixed",
    bottom: "10px",
    left: "10px",
    width: "auto",
    zIndex: "10",
  },
  colorPink: {
    color: pink[500],
  },
  colorGreen: {
    color: green[500],
  },
  colorOrange: {
    color: orange[500],
  },
};

const useStyles = makeStyles(styles);

const statusList = ["Whatever", "Yes", "No"];
const activeStatus = ["Active", "Deleted"];
const orders = ["Asc", "Desc"];
const sexes = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "1",
    label: "Women",
  },
  {
    value: "0",
    label: "Man",
  },
  {
    value: "2",
    label: "Non gendered",
  },
];

let sexesMap = {};
sexes.forEach((sex) => {
  sexesMap[sex.value] = sex.label;
});

const nbUsersToFetch = 10;

export default function Users() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const initColumns = [
    {
      columnName: "First Name",
      getData: (user, users, index) => (
        <>
          <Button
            className={
              user.selected ? classes.dangerButton : classes.infoButton
            }
            onClick={() => handleUserSelectionToggle(user, users, index)}
            variant="contained"
          >
            {user.selected ? "Del" : "Add"}
          </Button>
          <TextField
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
            variant="outlined"
            value={user.firstName}
            onChange={(event) =>
              handleUserChange(event, user, "firstName", users, index)
            }
            onBlur={() => updateUserInfo(user)}
          />
        </>
      ),
      getCompareData: (user) => user.firstName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "User Name",
      getData: (user, users, index) => (
        <TextField
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
          variant="outlined"
          value={user.userName}
          onChange={(event) =>
            handleUserChange(event, user, "userName", users, index)
          }
          onBlur={() => updateUserInfo(user)}
        />
      ),
      getCompareData: (user) => user.userName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Sex",
      getData: (user) => sexesMap[user.sex],
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Mail",
      getData: (user, users, index) => (
        <TextField
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
          variant="outlined"
          value={user.mail}
          onChange={(event) =>
            handleUserChange(event, user, "mail", users, index)
          }
          onBlur={() => updateUserInfo(user)}
        />
      ),
      getCompareData: (user) => user.mail,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Mail verified",
      getData: (user) => (user.emailVerified ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Mail undeliverable",
      getData: (user) => (user.emailUndeliverable ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "City",
      getData: (user) => user.city,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Country",
      getData: (user) => user.country,
      getCompareData: (user) => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Balance",
      getData: (user) => user.balance,
      getCompareData: (user) => user.balance,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "balance",
    },
    {
      columnName: "Balance forecast",
      getData: (user) => user.balanceForecast,
      getCompareData: (user) => user.balanceForecast,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "balanceForecast",
    },
    {
      columnName: "Global Tax",
      getData: (user, users, index) =>
        user.isModel ? (
          <TextField
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
            label="Global Tax"
            variant="outlined"
            value={user.tax}
            onChange={(event) =>
              handleUserChange(event, user, "tax", users, index)
            }
            onBlur={() => sendNewModelTax(user)}
          />
        ) : (
          <></>
        ),
      getCompareData: (user) => user.tax,
      isFilterable: false,
      isActive: true,
      isMandatory: false,
    },
    {
      columnName: "Uncove Tax",
      getData: (user) =>
        user.isModel ? (
          user.tax -
          (user.godfatherName
            ? (user.godfatherTax * (100 - user.tax)) / 100
            : 0)
        ) : (
          <></>
        ),
      getCompareData: (user) =>
        user.tax - user.godfatherName
          ? (user.godfatherTax * (100 - user.tax)) / 100
          : 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false,
    },
    {
      columnName: "Godfather Tax",
      getData: (user, users, index) => (
        <TextField
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
          label="Godfather Tax"
          variant="outlined"
          value={user.godfatherTax ? user.godfatherTax : 0}
          onChange={(event) =>
            handleGodfatherTaxChange(event, user, users, index)
          }
          onBlur={() => sendNewGodfatherTax(user)}
        />
      ),
      getCompareData: (user) => user.godfatherTax,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "godfather",
    },
    {
      columnName: "Godfather Name",
      getData: (user) => user.godfatherName ?? "",
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "godfather",
    },
    {
      columnName: "Super Godfather Tax",
      getData: (user, users, index) => (
        <TextField
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
          label="SG Tax"
          variant="outlined"
          value={user.superGodFatherTax ?? 0}
          onChange={(event) =>
            handleSuperGodfatherTaxChange(event, user, users, index)
          }
          onBlur={() => sendNewSuperGodfatherTax(user)}
        />
      ),
      getCompareData: (user) => user.godfatherTax,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "godfather",
    },
    {
      columnName: "Promoter Tax",
      getData: (user, users, index) => (
        <TextField
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
          label="Promoter Tax"
          variant="outlined"
          value={user.promoterTax ? user.promoterTax : 0}
          onChange={(event) =>
            handlePromoterTaxChange(event, user, users, index)
          }
          onBlur={() => sendNewPromoterTax(user)}
        />
      ),
      getCompareData: (user) => user.promoterTax,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "promoterTax",
    },
    {
      columnName: "Model Subscribed",
      getData: (user) => user.modelSubscribedCount,
      getCompareData: (user) => user.modelSubscribedCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "modelSubscribedCount",
    },
    {
      columnName: "Subscribers",
      getData: (user) => user.subscribersCount,
      getCompareData: (user) => user.subscribersCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "subscribersCount",
    },
    {
      columnName: "Model Followed",
      getData: (user) => user.modelFollowedCount,
      getCompareData: (user) => user.modelFollowedCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "modelFollowedCount",
    },
    {
      columnName: "Followers",
      getData: (user) => user.followersCount,
      getCompareData: (user) => user.followersCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "followersCount",
    },
    {
      columnName: "Private Media",
      getData: (user) =>
        user.privateMedia ? <b>{user.privateMedia}</b> : user.privateMedia,
      getCompareData: (user) => user.privateMedia,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "privateMedia",
    },
    {
      columnName: "Private Media Bought",
      getData: (user) => user.privateMediaBought,
      getCompareData: (user) => user.privateMediaBought,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "privateMediaBought",
    },
    {
      columnName: "Private Media Sold",
      getData: (user) => user.privateMediaSold,
      getCompareData: (user) => user.privateMediaSold,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "privateMediaSold",
    },
    {
      columnName: "Conversations",
      getData: (user) => user.conversationsCount,
      getCompareData: (user) => user.conversationsCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "conversationsCount",
    },
    {
      columnName: "Unsafe coef",
      getData: (user) => (user.unsafeCoefficient ? user.unsafeCoefficient : 0),
      getCompareData: (user) =>
        user.unsafeCoefficient ? user.unsafeCoefficient : 0,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Photos",
      getData: (user) => user.photosCount,
      getCompareData: (user) => user.photosCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "photosCount",
    },
    {
      columnName: "Videos",
      getData: (user) => user.videosCount,
      getCompareData: (user) => user.videosCount,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "videosCount",
    },
    {
      columnName: "Age",
      getData: (user) => getAge(user.birthday),
      getCompareData: (user) => getAge(user.birthday),
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "birthday",
    },
    {
      columnName: "Category",
      getData: (user, users, userIndex, { allSubTypes, tr }) =>
        user.detailedStatus &&
        user.detailedStatus.length > 1 &&
        user.detailedStatus[1] !== "fan" ? (
          <Select
            onChange={(evt) =>
              handleUserSubtypeChanged(evt, user, users, allSubTypes, tr)
            }
            renderValue={(selected) => selected}
            value={tr.userOption[user.detailedStatus[1]]}
          >
            {allSubTypes.map((type) => (
              <MenuItem key={type.keyname} value={type.keyname}>
                <Checkbox
                  checked={userSubTypesSelected.indexOf(type._id) > -1}
                />
                <ListItemText
                  primary={tr.userOption ? tr.userOption[type.keyname] : ""}
                />
              </MenuItem>
            ))}
          </Select>
        ) : (
          <>
            {user.detailedStatus[0] !== null
              ? user.detailedStatus[0] + " > " + user.detailedStatus[1]
              : user.detailedStatus[1]}
          </>
        ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "detailedStatus",
    },
    {
      columnName: "Score",
      getData: (user, users, userIndex, { allSubTypes, tr }) =>
        typeof user.score !== undefined ? (
          <TextField
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
            label="Score"
            variant="outlined"
            value={user.score || 0}
            onChange={(evt) =>
              handleScoreChange(evt, user, users, allSubTypes, tr)
            }
            onBlur={() => saveScore(user)}
          />
        ) : (
          <></>
        ),
      getCompareData: (user) => user.score,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "score",
    },
    {
      columnName: "Keep score",
      getData: (user, users, userIndex, { allSubTypes, tr }) => (
        <Checkbox
          onClick={() => handleKeepScoreChanged(user, users, allSubTypes, tr)}
          checked={user.keepScore}
        />
      ),
      getCompareData: () => 0,
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "score",
    },
    {
      columnName: "Browser",
      getData: (user) => (user?.device?.isBrowser ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "device",
    },
    {
      columnName: "Tablet",
      getData: (user) => (user?.device?.isTablet ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "device",
    },
    {
      columnName: "Mobile",
      getData: (user) => (user?.device?.isMobile ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "device",
    },
    {
      columnName: "Installed",
      getData: (user) => (user?.device?.standalone ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
      fieldDb: "device",
    },
    {
      columnName: "Last Connection",
      getData: (user) => {
        if (user.lastConnection) {
          var now = new Date().getTime();
          var lastConnection = new Date(user.lastConnection).getTime();
          var Difference_In_Time = now - lastConnection;
          var DiffInSec = Difference_In_Time / 1000;
          var DiffInMin = Difference_In_Time / (1000 * 60);
          var DiffInHour = Difference_In_Time / (1000 * 3600);
          var DiffInDays = Difference_In_Time / (1000 * 3600 * 24);
          if (DiffInDays >= 1) return Math.round(DiffInDays) + " days";
          if (DiffInHour >= 1) return Math.round(DiffInHour) + " hours";
          if (DiffInMin >= 1) return Math.round(DiffInMin) + " min";
          if (DiffInSec >= 1) return Math.round(DiffInSec) + " sec";
        }
        return "";
      },
      getCompareData: (user) => new Date(user.lastConnection).getTime(),
      isFilterable: true,
      isActive: true,
      isMandatory: false,
      fieldDb: "lastConnection",
    },
    {
      columnName: "Registration Date",
      getData: (user) => new Date(user.registrationDate).toLocaleDateString(),
      getCompareData: () => {},
      isFilterable: true,
      isActive: true,
      isMandatory: false,
      fieldDb: "registrationDate",
    },
    {
      columnName: "Facebook",
      getData: (user, users) => (
        <>
          <Button
            className={classes.infoButton}
            onClick={() => showSocialNetwork(user, users, "facebook")}
            variant="contained"
          >
            Show
          </Button>
          <Button
            className={classes.dangerButton}
            onClick={() => hideSocialNetwork(user, users, "facebook")}
            variant="contained"
          >
            Hide
          </Button>
        </>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Instagram",
      getData: (user, users) => (
        <>
          <Button
            className={classes.infoButton}
            onClick={() => showSocialNetwork(user, users, "instagram")}
            variant="contained"
          >
            Show
          </Button>
          <Button
            className={classes.dangerButton}
            onClick={() => hideSocialNetwork(user, users, "instagram")}
            variant="contained"
          >
            Hide
          </Button>
        </>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Tiktok",
      getData: (user, users) => (
        <>
          <Button
            className={classes.infoButton}
            onClick={() => showSocialNetwork(user, users, "tiktok")}
            variant="contained"
          >
            Show
          </Button>
          <Button
            className={classes.dangerButton}
            onClick={() => hideSocialNetwork(user, users, "tiktok")}
            variant="contained"
          >
            Hide
          </Button>
        </>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Twitter",
      getData: (user, users) => (
        <>
          <Button
            className={classes.infoButton}
            onClick={() => showSocialNetwork(user, users, "twitter")}
            variant="contained"
          >
            Show
          </Button>
          <Button
            className={classes.dangerButton}
            onClick={() => hideSocialNetwork(user, users, "twitter")}
            variant="contained"
          >
            Hide
          </Button>
        </>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Suspended",
      getData: (user) => (user?.suspended ? "Yes" : "No"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Suspend",
      getData: (user, users) => (
        <p
          onClick={() => handleSuspendUser(user, users, true)}
          className={classes.actionText}
        >
          Suspend
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Unsuspend",
      getData: (user, users) => (
        <p
          onClick={() => handleSuspendUser(user, users, false)}
          className={classes.actionText}
        >
          Unsuspend
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Delete",
      getData: (user, users) => (
        <p
          onClick={() => handleDeleteUser(user, users)}
          className={classes.actionText}
        >
          Delete
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Key",
      getData: (user) => user._key,
      getCompareData: (user) => user._key,
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "IA",
      getData: (user, users) => {
        return (
          <Checkbox
            onClick={() => {
              handleAiToggleCheckboxChange(user, users);
            }}
            checked={user.ia}
          />
        );
      },
      getCompareData: () => {},
      isFilterable: true,
      isActive: false,
      isMandatory: false,
      fieldDb: "ia",
    },
    {
      columnName: "Verified",
      getData: (user) => {
        return (
          <p>
            {user.verifiedStatus ? (
              user.verifiedStatus == "identityAccepted" ? (
                <span className={classes.colorGreen}>Accepted</span>
              ) : user.verifiedStatus == "identityNotAccepted" ? (
                <span className={classes.colorPink}>Refused</span>
              ) : (
                <span className={classes.colorOrange}>Waiting</span>
              )
            ) : (
              ""
            )}
          </p>
        );
      },
      getCompareData: () => {},
      isFilterable: true,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Email link",
      getData: (user) => {
        return (
          <Button
            className={classes.infoButton}
            onClick={() => copyEmailLink(user)}
            variant="contained"
          >
            Copy
          </Button>
        );
      },
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "SP ID",
      getData: (user) => {
        return <p>{user.securionpay?.customerId || ""}</p>;
      },
      getCompareData: () => {},
      isFilterable: true,
      isActive: false,
      isMandatory: false,
    },
    {
      columnName: "Deletion date",
      getData: (user) => {
        return <p>{user.deletionDate ? user.deletionDate : null}</p>;
      },
      getCompareData: () => {},
      isFilterable: true,
      isActive: false,
      isMandatory: false,
    },
  ];

  const [tr, setTr] = useState({});
  const [countrySelected, setCountrySelected] = useState("");
  const [countries, setCountries] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [usersSelectedCount, setUsersSelectedCount] = useState(0);
  // const [toggle, setToggle] = useState(false); // needed to force refresh
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [mailOption, setMailOption] = useState(false);
  const [notificationOption, setNotificationOption] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState("Asc");
  const [columnFilter, setColumnFilter] = useState(
    initColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf("Age")
  );

  const [columns, setColumns] = useState(initColumns);
  const [search, setSearch] = useState("");
  const [sponsorBy, setSponsorBy] = useState("");
  const [sex, setSex] = useState("All");
  const [verifiedStatus, setVerifiedStatus] = useState("Whatever");
  const [iaStatus, setIaStatus] = useState("Whatever");
  const [godFatherStatus, setGodFatherStatus] = useState("Whatever");
  const [promoterStatus, setPromoterStatus] = useState("Whatever");
  const [superGodFatherStatus, setSuperGodFatherStatus] = useState("Whatever");
  const [agencyStatus, setAgencyStatus] = useState("Whatever");
  const [userActiveStatus, setUserActiveStatus] = useState("Active");
  const [hasGodFather, setHasGodFather] = useState("Whatever");
  const [lowIcomesStatus, setLowIcomesStatus] = useState("Whatever");
  const [country, setCountry] = useState("All");
  const [city, setCity] = useState("");
  const [currency, setCurrency] = React.useState({
    code: "eur",
    symbol: "€",
  });
  const [currencies, setCurrencies] = React.useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(nbUsersToFetch);
  // const [reset, setReset] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [userSubTypes, setUserSubTypes] = useState([]);
  const [allSubTypes, setAllSubTypes] = useState([]);
  const [userTypesSelected, setUserTypesSelected] = useState([]);
  const [userSubTypesSelected, setUserSubTypesSelected] = useState([]);
  const [copied, setCopied] = useState(false);

  const authService = new AuthService();
  const authServiceSingleton = React.useRef(new AuthService());

  useEffect(() => {
    // Get translations
    authService.setUrl(context.apiUrl + context.translationsPath);
    authService.request("get", { country: "EN", type: "site" }, (response) => {
      const { data } = response;
      let site = data.filter((e) => e.type === "site");
      if (data.length > 0) {
        data[0].translations.userOption.all = "All";
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }
    });

    // Get All countries
    authService.setUrl(context.apiUrl + context.geolocationPath + "countries");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setCountries(["All"].concat(data));
    });

    // Get user categories
    authService.setUrl(context.apiUrl + context.categoriesPath + "user");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setUserTypes(data);
      setUserTypesSelected(data.map((e) => e._id));
    });

    // Get all uncover subcategories
    authService.setUrl(context.apiUrl + context.categoriesPath + "user");
    authService.request("get", { parent: "all" }, (response) => {
      const { data } = response;
      setAllSubTypes(data);
    });
  }, []);

  useEffect(() => {
    searchUsers(false);
  }, []);

  useEffect(() => {
    searchUsers(true);
    getCurrencies();
  }, [
    userTypesSelected,
    userSubTypesSelected,
    verifiedStatus,
    iaStatus,
    godFatherStatus,
    promoterStatus,
    superGodFatherStatus,
    agencyStatus,
    userActiveStatus,
    hasGodFather,
    lowIcomesStatus,
    country,
    city,
    currency,
    sex,
    search,
    sponsorBy,
    columnFilter,
    order,
  ]);

  const getCurrencies = () => {
    const authService = new AuthService();

    authService.setUrl(
      context.apiUrl + context.categoriesPath + "currencies/all"
    );
    authService.request("get", {}, (response) => {
      const { data } = response;
      if (data && Array.isArray(data)) {
        setCurrencies(data);
      }
    });
  };

  const getAge = (date) => {
    var ageDifMs = Date.now() - new Date(date).getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const sendNewModelTax = (user) => {
    authService.setUrl(context.apiUrl + context.updateUserPath + "tax");
    authService.request(
      "post",
      {
        modelId: user._id,
        tax: Number(user.tax),
      },
      (response) => {
        if (response.data.error) {
          console.log(response.data.error);
        }
      }
    );
  };

  const sendNewGodfatherTax = (user) => {
    authService.setUrl(
      context.apiUrl + context.updateUserPath + "godfatherTax"
    );
    authService.request(
      "post",
      {
        userId: user._id,
        godfatherTax: user.godfatherTax,
      },
      (response) => {
        if (response.data.error) {
          console.log(response.data.error);
        }
      }
    );
  };

  const sendNewSuperGodfatherTax = (user) => {
    console.log({
      userId: user._id,
      superGodFatherTax: user.superGodFatherTax,
    });
    authService.setUrl(
      context.apiUrl + context.updateUserPath + "superGodFatherTax"
    );
    authService.request(
      "post",
      {
        userId: user._id,
        superGodFatherTax: user.superGodFatherTax,
      },
      (response) => {
        if (response.data.error) {
          console.log(response.data.error);
        }
      }
    );
  };

  const sendNewPromoterTax = (user) => {
    authService.setUrl(context.apiUrl + context.updateUserPath + "promoterTax");
    authService.request(
      "post",
      {
        userId: user._id,
        promoterTax: user.promoterTax,
      },
      (response) => {
        if (response.data.error) {
          console.log(response.data.error);
        }
      }
    );
  };

  const handleUserChange = (event, user, key, users, index) => {
    try {
      users[index][key] = event.target.value;
      setTableData(makeDataFromUsers(users));
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserSelectionToggle = (user, users, index) => {
    console.log(usersSelected);
    let slectedIndex = usersSelected.indexOf(user.userName);
    console.log(slectedIndex);
    if (slectedIndex === -1) {
      usersSelected.push(user.userName);
    } else {
      usersSelected.splice(slectedIndex, 1);
    }
    users[index].selected = !user.selected;
    console.log(usersSelected);
    setUsersSelected(usersSelected);
    setUsersSelectedCount(usersSelected.length);
    setTableData(makeDataFromUsers(users));
  };

  const updateUserInfo = (user) => {
    const data = {
      userName: user.userName,
      firstName: user.firstName,
      facebookName: user.facebookName,
      instagramName: user.instagramName,
      mail: user.mail,
      phone: user.phone,
      userId: user._id,
    };

    authService.setUrl(
      context.apiUrl + context.updateUserPath + "personalInfo"
    );
    authService.request("post", data, (response) => {
      if (response.data.error) {
        console.log(response.data.error);
      } else {
        console.log("Success !");
      }
    });
  };

  const handleGodfatherTaxChange = (event, user, users, index) => {
    if (user.isModel) {
      try {
        users[index].godfatherTax = Number(event.target.value);
        setTableData(makeDataFromUsers(users));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSuperGodfatherTaxChange = (event, user, users, index) => {
    try {
      users[index].superGodFatherTax = Number(event.target.value);
      setTableData(makeDataFromUsers(users));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePromoterTaxChange = (event, user, users, index) => {
    try {
      let newtax = Number(event.target.value);
      users[index].promoterTax = newtax;
      setTableData(makeDataFromUsers(users));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteUser = (user, users) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    authService.setUrl(context.apiUrl + context.usersPath);
    authService.request("delete", { userId: users[index]._id }, () => {});

    users.splice(index, 1);
    setTableData(makeDataFromUsers(users));
  };

  const handleSuspendUser = (user, users, suspend) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    authService.setUrl(context.apiUrl + context.usersPath + "suspend");
    authService.request("put", { userId: users[index]._id, suspend }, () => {});

    users[index].suspended = suspend;
    setTableData(makeDataFromUsers(users));
  };

  const copyEmailLink = (user) => {
    const emailLink = `https://uncove.com/activate/${user._key}`;
    copy(emailLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const showSocialNetwork = (user, users, socialNetwork) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    authService.setUrl(
      context.apiUrl + context.settingsHonoPath + "socialNetworkVisibility"
    );
    authService.request(
      "put",
      {
        userId: users[index]._id,
        socialNetwork,
        visibility: true,
      },
      () => {}
    );
  };

  const hideSocialNetwork = (user, users, socialNetwork) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    authService.setUrl(
      context.apiUrl + context.settingsHonoPath + "socialNetworkVisibility"
    );
    authService.request(
      "put",
      {
        userId: users[index]._id,
        socialNetwork,
        visibility: false,
      },
      () => {}
    );
  };

  const handleUserSubtypeChanged = (evt, user, users, allSubTypes, tr) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    let value = evt.target.value;
    users[index].detailedStatus[1] = value;
    let categoryId = allSubTypes.filter((e) => e.keyname === value)[0]._id;

    setTableData(makeDataFromUsers(users, allSubTypes, tr));

    authService.setUrl(context.apiUrl + context.usersPath + "category");
    authService.request(
      "put",
      {
        categoryId,
        userId: user._id,
      },
      () => {}
    );
  };

  const handleScoreChange = (evt, user, users, allSubTypes, tr) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    users[index].score = evt.target.value;
    setTableData(makeDataFromUsers(users, allSubTypes, tr));
  };

  const saveScore = (user) => {
    authService.setUrl(context.apiUrl + context.usersPath + "score");
    authService.request(
      "put",
      {
        score: Number(user.score),
        keepScore: Boolean(user.keepScore),
        userId: user._id,
      },
      () => {}
    );
  };

  const handleKeepScoreChanged = (user, users, allSubTypes, tr) => {
    let index = users.map((u) => u._id).indexOf(user._id);
    users[index].keepScore = !Boolean(users[index].keepScore);
    setTableData(makeDataFromUsers(users, allSubTypes, tr));

    authService.setUrl(context.apiUrl + context.usersPath + "score");
    authService.request(
      "put",
      {
        score: Number(users[index].score),
        keepScore: Boolean(users[index].keepScore),
        userId: user._id,
      },
      () => {}
    );
  };

  /**
   * Toggles the `ia` property of a user and updates the table data accordingly.
   * Makes a `POST /users/ia` request to update the `ia` property on the server side.
   *
   * @param {Object} user The user object whose `ia` property needs to be toggled.
   * @param {Array<Object>} users An array of user objects.
   */
  const handleAiToggleCheckboxChange = (user, users) => {
    const index = users.map((u) => u._id).indexOf(user._id);

    users[index].ia = !Boolean(users[index].ia);

    setTableData(makeDataFromUsers(users, allSubTypes, tr));

    authService.setUrl(context.apiUrl + context.usersPath + "ia");
    authService.request(
      "put",
      {
        userId: user._id,
        value: users[index].ia,
      },
      () => {}
    );
  };

  const handleColumnFilterChange = (event) => {
    setColumnFilter(event.target.value);
    clearSearch();
  };

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
    clearSearch();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    // clearSearch();
  };

  const handleSponsorByChange = (event) => {
    setSponsorBy(event.target.value);
  };

  const handleCityChange = (event) => {
    let city = event.target.value;
    setCity(city);
    clearSearch();
  };

  const handleCurrencyChange = (event) => {
    let index = currencies.map((e) => e.code).indexOf(event.target.value);
    setCurrency(currencies[index]);
  };

  const clearSearch = () => {
    setPageLoaded(false);
    // setReset(true);
    setTableData([]);
    // setStart(0);
    // setEnd(nbUsersToFetch);
    searchUsers(true);
  };

  const handleUserTypeChange = (evt) => {
    let { value } = evt.target;
    clearSearch();
    setUserSubTypesSelected([]);
    setUserTypesSelected(typeof value === "string" ? value.split(",") : value);
    authService.setUrl(context.apiUrl + context.categoriesPath + "user");
    authService.request("get", { parent: value[0] }, (response) => {
      const { data } = response;
      clearSearch();
      setUserSubTypes(data);
      setUserSubTypesSelected(data.map((e) => e._id));
    });
  };

  const handleUserSubTypeChange = (evt) => {
    let { value } = evt.target;
    setUserSubTypesSelected(
      typeof value === "string" ? value.split(",") : value
    );
    clearSearch();
  };

  const handleSexChange = (event) => {
    let sex = event.target.value;
    setSex(sex);
    clearSearch();
  };

  const handleVerifiedStatusChange = (event) => {
    let verifiedStatus = event.target.value;
    setVerifiedStatus(verifiedStatus);
    clearSearch();
  };

  const handleIaStatusChange = (event) => {
    let iaStatus = event.target.value;
    setIaStatus(iaStatus);
    clearSearch();
  };

  const handleGodfatherStatusChange = (event) => {
    let godfatherStatus = event.target.value;
    setGodFatherStatus(godfatherStatus);
    clearSearch();
  };

  const handlePromoterStatusChange = (event) => {
    let promoterStatus = event.target.value;
    setPromoterStatus(promoterStatus);
    clearSearch();
  };

  const handleAgencyStatusChange = event => {
    let agencyStatus = event.target.value;
    setAgencyStatus(agencyStatus);
    clearSearch();
  };

  const handleSuperGodFatherStatusChange = event => {
    let superGodFatherStatus = event.target.value;
    setSuperGodFatherStatus(superGodFatherStatus);
    clearSearch();
  };

  const handleUsersActiveStatusChange = (event) => {
    let status = event.target.value;
    setUserActiveStatus(status);
    clearSearch();
  };

  const handleHasGodFatherChange = (event) => {
    let hasGodFather = event.target.value;
    setHasGodFather(hasGodFather);
    clearSearch();
  };

  const handleLowIcomesStatusChange = (event) => {
    let lowIcomesStatus = event.target.value;
    setLowIcomesStatus(lowIcomesStatus);
    clearSearch();
  };

  const handleColumnStateChange = (index) => (event) => {
    let activeColumns = columns.filter((e) => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map((e) => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    clearSearch();
  };

  const handleCountryChange = (event) => {
    let country = event.target.value;
    setCountry(country);
  };

  const makeDataFromUsers = (
    allUsers,
    subTypes = null,
    translations = null
  ) => {
    var data = [];
    allUsers.forEach((user, userIndex) => {
      user.city = user.geolocation ? user.geolocation.city : "";
      user.country = user.geolocation ? user.geolocation.country : "";
      var row = [];
      columns.forEach((column, index) => {
        if (column.isActive) {
          row.push({
            value: column.getData(user, allUsers, userIndex, {
              allSubTypes: subTypes || allSubTypes,
              tr: translations || tr,
            }),
            compare: column.getCompareData(user),
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  const searchUsers = (reset) => {
    let columnsFiltered = columns.filter((c) => c.isActive);

    if (reset) {
      setTableData([]);
    }

    setSearchLoading(true);

    const data = {
      city: city ? city : "All",
      userTypes:
        userSubTypesSelected.length > 0
          ? userSubTypesSelected
          : userTypesSelected,
      sex,
      deleted: userActiveStatus === "Deleted",
      verifiedStatus: verifiedStatus.toLocaleLowerCase(),
      iaStatus: iaStatus.toLowerCase(),
      godFatherStatus: godFatherStatus.toLowerCase(),
      promoterStatus: promoterStatus.toLowerCase(),
      superGodFatherStatus: superGodFatherStatus.toLocaleLowerCase(),
      agencyStatus: agencyStatus.toLocaleLowerCase(),
      hasGodFather: hasGodFather.toLocaleLowerCase(),
      lowIcomesStatus: lowIcomesStatus.toLocaleLowerCase(),
      username: search,
      sponsorBy,
      start: reset ? 0 : start,
      end: reset ? nbUsersToFetch : end,
      country,
      currency: currency?.code ?? "",
      orderColumn: order.toUpperCase(),
      filterColumn: columnsFiltered[columnFilter]
        ? columnsFiltered[columnFilter].fieldDb
        : "",
      fieldsAsked: columns
        .filter((e) => e.isActive && e.fieldDb)
        .map((e) => e.fieldDb),
    };

    // Get all users
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.usersHonoPath + "all"
    );
    authServiceSingleton.current.request("get", data, (response) => {
      const { data } = response;
      setSearchLoading(false);
      if (data && Array.isArray(data)) {
        console.log(usersSelected);
        let newUsers = reset ? data : users.concat(data);
        newUsers.map((user) => {
          user.selected = usersSelected.indexOf(user.userName) !== -1;
          return user;
        });
        console.log(newUsers.filter((e) => e.selected).map((e) => e.userName));
        setUsers(newUsers);
        let newTable = makeDataFromUsers(newUsers);
        setTableData(newTable);

        if (newTable.length > 50) {
          setPageLoaded(true);
        }

        // setReset(false);
        let newStart = newUsers.length + 1;
        setStart(newStart);
        setEnd(newStart + nbUsersToFetch);

        // if (data.length == nbUsersToFetch && !pageLoaded) {
        //   setReset(false);
        //   let newStart = end + 1;
        //   setStart(newStart);
        //   setEnd(newStart + nbUsersToFetch);
        // }
      }
    });
  };

  const searchMore = () => {
    // Avoid to be triggered immediatly
    if (tableData.length > 0) {
      console.log("end : " + end);
      console.log("tableData : " + tableData.length);
      searchUsers(false);
      // if (end === tableData.length) {
      //   setStart(end);
      //   setReset(false);
      //   setEnd(end + nbUsersToFetch);
      // } else {
      //   searchUsers();
      // }
    }
  };

  // const handleUserSelectedDeleted = (userName) => {
  //   console.log("userName : " + userName);
  //   let index = usersSelected.indexOf(userName);
  //   console.log("index : " + index);
  //   usersSelected.splice(index, 1);
  //   setUsersSelected(usersSelected);
  //   setUsersSelectedCount(usersSelected.length);
  //   let userIndex = users.map(e => e.userName).indexOf(userName);
  //   console.log(users.map(e => e.userName));
  //   console.log("userIndex : " + userIndex);
  //   if (userIndex > -1) {
  //     users[userIndex].selected = !users[userIndex].selected;
  //     setTableData(makeDataFromUsers(users));
  //   }
  // }

  const sendNotification = () => {
    if (mailOption || notificationOption) {
      if (message) {
        if (!mailOption || countrySelected) {
          if (subject || !mailOption) {
            const data = {
              country: countrySelected,
              subject,
              message,
              usernames: usersSelected,
            };

            const authService = new AuthService();

            console.log(message);

            if (mailOption) {
              authService.setUrl(context.apiUrl + context.mailPath + "group");
              authService.request("post", data, (response) => {
                const { data } = response;
                setSubject("");
                setMessage("");
                alert(data.message);
              });
            }

            if (notificationOption) {
              authService.setUrl(
                context.apiUrl + context.notificationsHonoPath + "group"
              );
              authService.request("post", data, (response) => {
                const { data } = response;
                setMessage("");
                alert(data.message);
              });
            }
          } else {
            alert("Subject is empty");
          }
        } else {
          alert("Language is missing");
        }
      } else {
        alert("Message is empty");
      }
    } else {
      alert("Please, select a least one transport option.");
    }
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>User Categories</InputLabel>
        <Select
          multiple
          onChange={handleUserTypeChange}
          renderValue={(selected) =>
            selected
              .map((cat) => {
                let id = userTypes.map((e) => e._id).indexOf(cat);
                return userTypes[id] && tr.userOption
                  ? tr.userOption[userTypes[id].keyname]
                  : "";
              })
              .join(", ")
          }
          value={userTypesSelected}
        >
          {userTypes.map((type) => (
            <MenuItem key={type.keyname} value={type._id}>
              <Checkbox checked={userTypesSelected.indexOf(type._id) > -1} />
              <ListItemText
                primary={tr.userOption ? tr.userOption[type.keyname] : ""}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {userTypesSelected.length === 1 && (
        <FormControl className={classes.formControl}>
          <InputLabel>User Sub Categories</InputLabel>
          <Select
            multiple
            onChange={handleUserSubTypeChange}
            renderValue={(selected) =>
              selected
                .map((cat) => {
                  let id = userSubTypes.map((e) => e._id).indexOf(cat);
                  return userSubTypes[id] && tr.userOption
                    ? tr.userOption[userSubTypes[id].keyname]
                    : "";
                })
                .join(", ")
            }
            value={userSubTypesSelected}
          >
            {userSubTypes.map((type) => (
              <MenuItem key={type.keyname} value={type._id}>
                <Checkbox
                  checked={userSubTypesSelected.indexOf(type._id) > -1}
                />
                <ListItemText
                  primary={tr.userOption ? tr.userOption[type.keyname] : ""}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl className={classes.formControl}>
        <InputLabel>Sex</InputLabel>
        <Select onChange={handleSexChange} value={sex}>
          {sexes.map((sex) => (
            <MenuItem key={sex.value} value={sex.value}>
              {sex.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is Verified</InputLabel>
        <Select onChange={handleVerifiedStatusChange} value={verifiedStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is IA</InputLabel>
        <Select onChange={handleIaStatusChange} value={iaStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is Godfather</InputLabel>
        <Select onChange={handleGodfatherStatusChange} value={godFatherStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is Promoter</InputLabel>
        <Select onChange={handlePromoterStatusChange} value={promoterStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is super godfather</InputLabel>
        <Select
          onChange={handleSuperGodFatherStatusChange}
          value={superGodFatherStatus}
        >
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Is agency</InputLabel>
        <Select onChange={handleAgencyStatusChange} value={agencyStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Has Godfather</InputLabel>
        <Select onChange={handleHasGodFatherChange} value={hasGodFather}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Has Low Icomes</InputLabel>
        <Select onChange={handleLowIcomesStatusChange} value={lowIcomesStatus}>
          {statusList.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Active</InputLabel>
        <Select
          onChange={handleUsersActiveStatusChange}
          value={userActiveStatus}
        >
          {activeStatus.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Column to sort</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter((column) => column.isFilterable && column.isActive)
            .map((column) => {
              var index = columns
                .filter((column) => column.isActive)
                .map((field) => field.columnName)
                .indexOf(column.columnName);
              return <MenuItem value={index}>{column.columnName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Sort order</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map((order) => (
            <MenuItem key={order} value={order}>
              {order}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="User"
          type="search"
          variant="outlined"
          onChange={handleSearchChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Countries</InputLabel>
        <Select onChange={handleCountryChange} value={country}>
          {countries.map((countrie) => (
            <MenuItem key={countrie} value={countrie}>
              {countrie}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="City"
          type="search"
          variant="outlined"
          onChange={handleCityChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="Sponsor by"
          type="search"
          variant="outlined"
          onChange={handleSponsorByChange}
        />
      </FormControl>

      {columns.filter(
        (e) =>
          e.isActive &&
          (e.columnName === "Balance" || e.columnName === "Balance forecast")
      ).length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel>Currency</InputLabel>
          <Select onChange={handleCurrencyChange} value={currency.code}>
            {currencies.map((currency) => (
              <MenuItem value={currency.code}>{currency.code}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          key={column.columnName}
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      {usersSelected.length > 0 && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Selected Users ({usersSelectedCount})
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Here are all the selected users
                </p>
              </CardHeader>
              <CardBody>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  {usersSelected.map((userName) => (
                    <Box marginRight={1}>
                      <Chip
                        label={userName}
                        // onDelete={() => handleUserSelectedDeleted(userName)}
                      />
                      <p> </p>
                    </Box>
                  ))}
                </Box>

                <CardBody>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={mailOption}
                        onChange={() => setMailOption(!mailOption)}
                        color="secondary"
                      />
                    }
                    label="Mail"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={notificationOption}
                        onChange={() =>
                          setNotificationOption(!notificationOption)
                        }
                        color="secondary"
                      />
                    }
                    label="Notification"
                  />
                </CardBody>

                {mailOption && (
                  <>
                    <CardBody>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Language</InputLabel>
                        <Select
                          onChange={(evt) =>
                            setCountrySelected(evt.target.value)
                          }
                          value={countrySelected}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CardBody>

                    <CardBody>
                      <TextField
                        type="text"
                        className={classes.textField}
                        placeholder="Subject"
                        value={subject}
                        onChange={(evt) => setSubject(evt.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                    </CardBody>
                  </>
                )}

                <CardBody>
                  <TextField
                    type="text"
                    className={classes.textArea}
                    placeholder="Message here..."
                    multiline={true}
                    rows={3}
                    value={message}
                    onChange={(evt) => setMessage(evt.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </CardBody>

                <CardBody>
                  <Button
                    className={classes.infoButton}
                    onClick={sendNotification}
                    variant="contained"
                  >
                    Send
                  </Button>
                </CardBody>

                {/* <Button
                  className={classes.dangerButton}
                  onClick={() => {
                    setUsersSelected([]);
                    setUsersSelectedCount(0);
                  }}
                  variant="contained"
                >
                  Clear
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Users</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the users
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter((column) => column.isActive)
                  .map((column) => column.columnName)}
                tableData={tableData.map((e) => e.map((e) => e.value))}
              />
              {searchLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              <Movepoint
                onEnter={searchMore}
                onLeave={() => setPageLoaded(true)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {copied && (
        <Alert className={classes.successAlert} severity="success">
          Copied
        </Alert>
      )}
    </>
  );
}

import React, { useEffect, useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Autosuggest from "react-autosuggest";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Movepoint from "components/Movepoint/Movepoint.js";
import Table from "components/Table/Table.js";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/Delete";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  root: {
    backgroundColor: "#aaa",
    width: 500,
  },
  formControl: {
    display: "flex",
    marginTop: "10px",
    width: "610px",
    gap: "60px",
    justifyContent: "space-between",
    position: "relative",
    zIndex: "3",
  },
  formControlSelectSection: {
    display: "flex",
    margin: "10px 0",
    gap: 20,
  },
  formControlSelect: {
    width: 150,
  },
  formControlAutoSuggestSingle: {
    display: "flex",
    marginTop: "10px",
    width: "275px",
    position: "relative",
    zIndex: "3",
    marginTop: "20px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  centerAutoSuggest: {
    display: "flex",
    justifyContent: "center",
    gap: "60px",
    margin: "15px auto",
    width: "610px",
    transform: "translateX(-25px)",
    position: "relative",
    zIndex: "2",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    margin: "15px auto",
    width: "610px",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    height: "30px",
  },
  dangerButton: {
    background: "#d62546",
    color: "#fff",
    height: "30px",
  },
  inputText: {
    padding: "10px 20px",
  },
  deleteButton: {
    padding: "8px",
  },
  link: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#333",
  },
  colorRed: {
    color: "#d62546",
  },
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Sponsorship() {
  const context = useContext(AppContext);
  const classes = useStyles();
  const authServiceSingleton = React.useRef(new AuthService());

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    if (newTab == 0) {
      searchSponsorships(true /* reset */);
    } else if (newTab == 1) {
      searchUsersWithoutSponsorship(true /* reset */);
    } else if (newTab == 2) {
      searchSponsorshipIncomes(true /* reset */);
    }
  };

  const handleTabChangeIndex = (index) => {
    setTab(index);
    if (index == 0) {
      searchSponsorships(true /* reset */);
    } else if (index == 1) {
      searchUsersWithoutSponsorship(true /* reset */);
    } else if (index == 2) {
      searchSponsorshipIncomes(true /* reset */);
    }
  };

  //list months
  const monthNames = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //list years
  const years = ["All"];
  let staringDate = 2020;
  let date = new Date();
  for (let year = staringDate; year <= date.getFullYear(); ++year) {
    years.push(year);
  }

  // Table data
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(50);
  const [searchLoading, setSearchLoading] = useState(true);
  //tab 0
  const [tableDataSponsorship, setTableDataSponsorship] = useState([]);
  const [sponsorships, setSponsorships] = useState([]);
  const [numberSponsorships, setNumberSponsorships] = useState(0);
  //tab 1
  const [tableDataNonSponsorship, setTableDataNonSponsorship] = useState([]);
  const [nonSponsorships, setNonSponsorships] = useState([]);
  //tab 2
  const [
    tableDataSponsorshipIncomes,
    setTableDataSponsorshipIncomes,
  ] = useState([]);
  const [sponsorshipIncomes, setSponsorshipIncomes] = useState([]);

  // Filter by name
  // tab 0
  const [godFatherNameInput, setGodFatherNameInput] = useState("");
  const [godFatherName, setGodFatherName] = useState("");
  const [godSonNameInput, setGodSonNameInput] = useState("");
  const [godSonName, setGodSonName] = useState("");
  const [godSonSuggestions, setGodSonSuggestions] = useState([]);
  const [godFatherSuggestions, setGodFatherSuggestions] = useState([]);
  // tab 2
  const [godFatherNameIncomesInput, setGodFatherNameIncomesInput] = useState(
    ""
  );
  const [godFatherNameIncomes, setGodFatherNameIncomes] = useState("");
  const [
    godFatherIncomesSuggestions,
    setGodFatherIncomesSuggestions,
  ] = useState([]);

  // Filter by status
  //tab 0
  const [showFans, setShowFans] = useState(true);
  const [showCreators, setShowCreators] = useState(true);
  //tab 1
  const [showActive, setShowActive] = useState(true);

  //Filter by date
  //tab 2
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState("All");

  // New referral link
  const [addReferralSection, setAddReferralSection] = useState(false);
  const [newGodFatherName, setNewGodFatherName] = useState("");
  const [newGodSonName, setNewGodSonName] = useState("");
  const [newGodSonSuggestions, setNewGodSonSuggestions] = useState([]);
  const [newGodFatherSuggestions, setNewGodFatherSuggestions] = useState([]);

  // Delete referral link
  const [openDialogDeleting, setOpenDialogDeleting] = useState(false);
  const [referralLinkToDelete, setReferralLinkToDelete] = useState(null);

  // Sponsorship Table Columns (tab 0)
  let initColumnsSponsorship = [
    {
      columnName: "Godfather Name",
      getData: (data) => (
        <p
          className={classes.link}
          onClick={() => onSuggestionSelected(data.godFatherName, "godFather")}
        >
          {data.godFatherName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Godfather Alias",
      getData: (data) => (
        <p>{data.godFatherAlias}</p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Godson Name",
      getData: (data) => (
        <p
          className={classes.link}
          onClick={() => onSuggestionSelected(data.godSonName, "godSon")}
        >
          {data.godSonName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Delete",
      getData: (data) => (
        <IconButton
          aria-label="delete"
          className={classes.deleteButton}
          onClick={() => handleOpenDialogDeleting(data)}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      ),
      getCompareData: (data) => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];
  const [columnsSponsorship, setColumnsSponsorship] = useState(
    initColumnsSponsorship
  );

  // Non Sponsorship Table Columns (tab 1)
  let initColumnsNonSponsorship = [
    {
      columnName: "User Name",
      getData: (data) => (
        <b
          className={classes.link}
          onClick={() =>
            window.open(`https://uncove.com/${data.userName}`, "blank")
          }
        >
          {data.userName}
        </b>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "First Name",
      getData: (data) => <p>{data.firstName}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Last Name",
      getData: (data) => <p>{data.lastName}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Email",
      getData: (data) => <p>{data.email}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];
  const [columnsNonSponsorship, setColumnsNonSponsorship] = useState(
    initColumnsNonSponsorship
  );

  // Sponsorship Incomes Table Columns (tab 2)
  let initColumnsSponsorshipIncomes = [
    {
      columnName: "Name",
      getData: (data) => (
        <p
          className={classes.link}
          onClick={() => onSuggestionSelected(data.name, "godFatherIncomes")}
        >
          {data.name}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Email",
      getData: (data) => <p>{data.mail}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Parrainage",
      getData: (data) => (
        <p>
          {data.sponsorshipIncomes} {data.currency}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Promotion",
      getData: (data) => (
        <p>
          {data.promotionIncomes} {data.currency}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Total",
      getData: (data) => (
        <p>
          {data.incomes} {data.currency}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];
  const [columnsSponsorshipIncomes, setColumnsSponsorshipIncomes] = useState(
    initColumnsSponsorshipIncomes
  );

  useEffect(() => {
    if (tab == 0) {
      searchSponsorships(true /* reset */);
    }
  }, [showCreators, showFans, godFatherName, godSonName]);

  useEffect(() => {
    if (tab == 1) {
      searchUsersWithoutSponsorship(true /* reset */);
    }
  }, [showActive]);

  useEffect(() => {
    if (tab == 2) {
      searchSponsorshipIncomes(true /* reset */);
    }
  }, [godFatherNameIncomes, year, month]);

  const searchMoreSponsorship = (reset) => {
    // Avoid to be triggered immediatly
    if (tableDataSponsorship.length > 0) {
      searchSponsorships(reset);
    }
  };
  const searchSponsorships = (reset) => {
    authServiceSingleton.current.cancel();
    setSearchLoading(true);

    // Get all transactions
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.usersHonoPath + "sponsorship"
    );
    authServiceSingleton.current.request(
      "get",
      {
        godFatherName,
        godSonName,
        start: reset ? 0 : start,
        end: reset ? 50 : end,
        fans: showFans,
        creators: showCreators,
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);

        if (reset) setNumberSponsorships(data.count);
        let newSponsorships = reset
          ? data.sponsorships
          : sponsorships.concat(data.sponsorships);
        setSponsorships(newSponsorships);
        let newTabledata = makeDataFromSponsorship(
          newSponsorships,
          columnsSponsorship
        );
        setTableDataSponsorship(newTabledata);
        let newStart = newSponsorships.length;
        let newEnd = newStart + 50;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  const searchMoreNonSponsorship = (reset) => {
    // Avoid to be triggered immediatly
    if (tableDataNonSponsorship.length > 0) {
      searchUsersWithoutSponsorship(reset);
    }
  };
  const searchUsersWithoutSponsorship = (reset) => {
    setSearchLoading(true);

    authServiceSingleton.current.setUrl(
      context.apiUrl + context.usersHonoPath + "sponsorship/free"
    );
    authServiceSingleton.current.request(
      "get",
      {
        start: reset ? 0 : start,
        end: reset ? 50 : end,
        active: showActive,
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);

        let newSponsorships = reset ? data : nonSponsorships.concat(data);
        setNonSponsorships(newSponsorships);
        let newTabledata = makeDataFromSponsorship(
          newSponsorships,
          columnsNonSponsorship
        );
        setTableDataNonSponsorship(newTabledata);
        let newStart = newSponsorships.length;
        let newEnd = newStart + 50;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  const searchMoreSponsorshipIncomes = (reset) => {
    // Avoid to be triggered immediatly
    if (tableDataSponsorshipIncomes.length > 0) {
      searchSponsorshipIncomes(reset);
    }
  };
  const searchSponsorshipIncomes = (reset) => {
    setSearchLoading(true);

    authServiceSingleton.current.setUrl(
      context.apiUrl + context.usersHonoPath + "sponsorship/incomes"
    );
    authServiceSingleton.current.request(
      "get",
      {
        start: reset ? 0 : start,
        end: reset ? 50 : end,
        username: godFatherNameIncomes,
        month: month || "All",
        year: year || "All",
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);

        let newSponsorships = reset ? data : sponsorshipIncomes.concat(data);
        setSponsorshipIncomes(newSponsorships);
        let newTabledata = makeDataFromSponsorship(
          newSponsorships,
          columnsSponsorshipIncomes
        );
        setTableDataSponsorshipIncomes(newTabledata);
        let newStart = newSponsorships.length;
        let newEnd = newStart + 50;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  const makeDataFromSponsorship = (sponsorships, colums) => {
    var data = [];

    sponsorships.forEach((sponsorship) => {
      let row = [];
      colums.forEach((column) => {
        if (column.isActive) {
          row.push({
            value: column.getData(sponsorship),
            compare: column.getCompareData(sponsorship),
          });
        }
      });
      data.push(row);
    });

    return data;
  };

  const handleUserTypeChange = (type) => {
    if (type == "creators") {
      setShowCreators(!showCreators);
    } else if (type == "fans") {
      setShowFans(!showFans);
    }
  };

  const handleUserNameChange = (event, type) => {
    authServiceSingleton.current.cancel();
    setSearchLoading(true);
    setTableDataSponsorshipIncomes([]);

    let username = event.target.value;
    if (username != undefined) {
      if (type == "godFather") {
        setGodFatherNameInput(username);
        if (godFatherName) setGodFatherName("");
      } else if (type == "godSon") {
        setGodSonNameInput(username);
        if (godSonName) setGodSonName("");
      } else if (type == "newGodFather") {
        setNewGodFatherName(username);
      } else if (type == "newGodSon") {
        setNewGodSonName(username);
      } else if (type == "godFatherIncomes") {
        setGodFatherNameIncomesInput(username);
        if (godFatherNameIncomes) setGodFatherNameIncomes("");
      }

      authServiceSingleton.current.setUrl(
        context.apiUrl + context.usersHonoPath + "suggestion"
      );
      authServiceSingleton.current.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          if (type == "godFather") {
            setGodFatherSuggestions(data);
          } else if (type == "godSon") {
            setGodSonSuggestions(data);
          } else if (type == "newGodFather") {
            setNewGodFatherSuggestions(data);
          } else if (type == "newGodSon") {
            setNewGodSonSuggestions(data);
          } else if (type == "godFatherIncomes") {
            setGodFatherIncomesSuggestions(data);
          }
        }
      );
    }
  };

  const onSuggestionSelected = (name, type) => {
    if (type == "godFather") {
      setGodFatherNameInput(name);
      setGodFatherName(name);
    } else if (type == "godSon") {
      setGodSonNameInput(name);
      setGodSonName(name);
    } else if (type == "newGodFather") {
      setNewGodFatherName(name);
    } else if (type == "newGodSon") {
      setNewGodSonName(name);
    } else if (type == "godFatherIncomes") {
      setGodFatherNameIncomesInput(name);
      setGodFatherNameIncomes(name);
    }
  };

  const godFatherInputProps = {
    placeholder: "Godfather Name",
    onChange: (event) => handleUserNameChange(event, "godFather"),
    value: godFatherNameInput,
  };

  const godSonInputProps = {
    placeholder: "Godson Name",
    onChange: (event) => handleUserNameChange(event, "godSon"),
    value: godSonNameInput,
  };

  const newGodFatherInputProps = {
    placeholder: "New Godfather Name",
    onChange: (event) => handleUserNameChange(event, "newGodFather"),
    value: newGodFatherName,
  };

  const newGodSonInputProps = {
    placeholder: "New Godson Name",
    onChange: (event) => handleUserNameChange(event, "newGodSon"),
    value: newGodSonName,
  };

  const godFatherIncomesInputProps = {
    placeholder: "Godfather Name",
    onChange: (event) => handleUserNameChange(event, "godFatherIncomes"),
    value: godFatherNameIncomesInput,
  };

  const addReferralBetweenUsers = () => {
    if (newGodFatherName && newGodSonName) {
      authServiceSingleton.current.setUrl(
        context.apiUrl + context.usersPath + "sponsorship"
      );
      authServiceSingleton.current.request(
        "post",
        {
          godFatherName: newGodFatherName,
          godSonName: newGodSonName,
        },
        (response) => {
          console.log("post", response.data);
          setNewGodSonName("");
          setNewGodFatherName("");
          setAddReferralSection(false);
          if (tab == 0) {
            searchSponsorships(true /* reset */);
          } else if (tab == 1) {
            searchUsersWithoutSponsorship(true /* reset */);
          }
        }
      );
    }
  };

  // Delete referral link
  const deleteReferralBetweenUsers = () => {
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.usersPath + "sponsorship"
    );
    authServiceSingleton.current.request(
      "delete",
      {
        godFatherId: referralLinkToDelete.godFatherId,
        godSonId: referralLinkToDelete.godSonId,
      },
      (response) => {
        console.log("delete", response.data);
        setOpenDialogDeleting(false);
        setReferralLinkToDelete(null);
        searchSponsorships(true /* reset */);
      }
    );
  };

  const handleOpenDialogDeleting = (data) => {
    setOpenDialogDeleting(true);
    setReferralLinkToDelete(data);
  };

  const handleCloseDialogDeleting = () => {
    setOpenDialogDeleting(false);
    setReferralLinkToDelete(null);
  };

  const handleMonthChange = (month) => {
    authServiceSingleton.current.cancel();
    setSearchLoading(true);
    setTableDataSponsorshipIncomes([]);
    setMonth(month);
  };

  const handleYearChange = (year) => {
    authServiceSingleton.current.cancel();
    setSearchLoading(true);
    setTableDataSponsorshipIncomes([]);
    setYear(year);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Sponsorship</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all about sponsorship
              </p>
            </CardHeader>
            <CardBody>
              <AppBar position="static" color="default">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Users with sponsorship" />
                  <Tab label="Users without sponsorship" />
                  <Tab label="Sponsor incomes" />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={"x"}
                index={tab}
                onChangeIndex={handleTabChangeIndex}
              >
                <TabPanel value={tab} index={0} dir={""}></TabPanel>
                <TabPanel value={tab} index={1} dir={""}></TabPanel>
                <TabPanel value={tab} index={2} dir={""}></TabPanel>
              </SwipeableViews>
              {tab == 0 && (
                <>
                  {columnsSponsorship.map((column, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={column.isMandatory}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          checked={column.isActive}
                          onChange={() => {}}
                          color="secondary"
                        />
                      }
                      label={column.columnName}
                    />
                  ))}
                  <div>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          onChange={() => handleUserTypeChange("creators")}
                          checked={showCreators}
                        />
                      }
                      label="Creators"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          onChange={() => handleUserTypeChange("fans")}
                          checked={showFans}
                        />
                      }
                      label="Fans"
                    />
                  </div>
                  <div className={classes.formControl}>
                    <Autosuggest
                      suggestions={godFatherSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(godFatherNameInput?.toLowerCase()) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(data.suggestionValue, "godFather")
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={godFatherInputProps}
                    />
                    <Autosuggest
                      suggestions={godSonSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(godSonNameInput?.toLowerCase()) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(data.suggestionValue, "godSon")
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={godSonInputProps}
                    />
                  </div>
                  <p>
                    Number of Sponsorships :{" "}
                    <span>
                      {searchLoading && (
                        <CircularProgress color="secondary" size={13} />
                      )}
                      {!searchLoading && <>{numberSponsorships}</>}
                    </span>
                  </p>
                </>
              )}
              {tab == 1 && (
                <>
                  {columnsNonSponsorship.map((column, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={column.isMandatory}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          checked={column.isActive}
                          onChange={() => {}}
                          color="secondary"
                        />
                      }
                      label={column.columnName}
                    />
                  ))}
                  <div>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          onChange={() => setShowActive(!showActive)}
                          checked={showActive}
                        />
                      }
                      label="Active"
                    />
                  </div>{" "}
                </>
              )}

              {tab == 2 && (
                <>
                  {columnsSponsorshipIncomes.map((column, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={column.isMandatory}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          checked={column.isActive}
                          onChange={() => {}}
                          color="secondary"
                        />
                      }
                      label={column.columnName}
                    />
                  ))}
                  <div className={classes.formControlSelectSection}>
                    <FormControl>
                      <InputLabel>Year</InputLabel>
                      <Select
                        className={classes.formControlSelect}
                        onChange={(event) =>
                          handleYearChange(event.target.value)
                        }
                        value={year}
                      >
                        {years.map((y) => (
                          <MenuItem value={y}>{y}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>Month</InputLabel>
                      <Select
                        className={classes.formControlSelect}
                        onChange={(event) =>
                          handleMonthChange(event.target.value)
                        }
                        value={month}
                      >
                        {monthNames.map((m, index) => (
                          <MenuItem value={index}>{m}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes.formControlAutoSuggestSingle}>
                    <Autosuggest
                      suggestions={godFatherIncomesSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(
                              godFatherNameIncomesInput?.toLowerCase()
                            ) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(
                          data.suggestionValue,
                          "godFatherIncomes"
                        )
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={godFatherIncomesInputProps}
                    />
                  </div>
                </>
              )}

              {tab != 2 && !addReferralSection && (
                <div className={classes.center}>
                  <Button
                    className={classes.infoButton}
                    onClick={() => setAddReferralSection(true)}
                    variant="contained"
                  >
                    Add sponsorship
                  </Button>
                </div>
              )}
              {tab != 2 && addReferralSection && (
                <>
                  <p className={classes.center}>Add sponsorship</p>
                  <div className={classes.centerAutoSuggest}>
                    <Autosuggest
                      suggestions={newGodFatherSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(newGodFatherName?.toLowerCase()) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(
                          data.suggestionValue,
                          "newGodFather"
                        )
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={newGodFatherInputProps}
                    />
                    <Autosuggest
                      suggestions={newGodSonSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(newGodSonName?.toLowerCase()) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(data.suggestionValue, "newGodSon")
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={newGodSonInputProps}
                    />
                  </div>

                  <div className={classes.center}>
                    <Button
                      className={classes.infoButton}
                      onClick={addReferralBetweenUsers}
                      variant="contained"
                    >
                      Add
                    </Button>
                    <Button
                      className={classes.dangerButton}
                      onClick={() => setAddReferralSection(false)}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </div>
                </>
              )}
              {tab == 0 && (
                <Table
                  tableHeaderColor="primary"
                  tableHead={columnsSponsorship
                    .filter((column) => column.isActive)
                    .map((column) => column.columnName)}
                  tableData={tableDataSponsorship.map((e) =>
                    e.map((e) => e.value)
                  )}
                />
              )}
              {tab == 1 && (
                <Table
                  tableHeaderColor="primary"
                  tableHead={columnsNonSponsorship
                    .filter((column) => column.isActive)
                    .map((column) => column.columnName)}
                  tableData={tableDataNonSponsorship.map((e) =>
                    e.map((e) => e.value)
                  )}
                />
              )}
              {tab == 2 && (
                <Table
                  tableHeaderColor="primary"
                  tableHead={columnsSponsorshipIncomes
                    .filter((column) => column.isActive)
                    .map((column) => column.columnName)}
                  tableData={tableDataSponsorshipIncomes.map((e) =>
                    e.map((e) => e.value)
                  )}
                />
              )}
              {searchLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              <Movepoint
                onEnter={() => {
                  if (tab == 0) {
                    searchMoreSponsorship(false /* reset */);
                  } else if (tab == 1) {
                    searchMoreNonSponsorship(false /* reset */);
                  } else if (tab == 2) {
                    searchMoreSponsorshipIncomes(false /* reset */);
                  }
                }}
                onLeave={() => {}}
              />
              <Dialog
                open={openDialogDeleting && referralLinkToDelete}
                onClose={handleCloseDialogDeleting}
                maxWidth={false}
              >
                {referralLinkToDelete && (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      Delete the referral link between{" "}
                      <span className={classes.colorRed}>
                        {referralLinkToDelete.godFatherName}
                      </span>{" "}
                      and{" "}
                      <span className={classes.colorRed}>
                        {referralLinkToDelete.godSonName}
                      </span>
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        onClick={handleCloseDialogDeleting}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={deleteReferralBetweenUsers}
                        color="secondary"
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Update from "@material-ui/icons/Update";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Photo from "@material-ui/icons/Photo";
import BubbleChart from "@material-ui/icons/BubbleChart";
import List from "@material-ui/icons/List";
import Loyalty from "@material-ui/icons/Loyalty";
import CreditCardIcon from "@material-ui/icons/CreditCardOutlined";
import MmsIcon from "@material-ui/icons/Mms";
import BlockOutlined from "@material-ui/icons/BlockOutlined";
import RedeemIcon from "@material-ui/icons/RedeemOutlined";
import WalletIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
// Core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
// Charts
import { Chart } from "react-google-charts";
// Autocomplete
import Autosuggest from "react-autosuggest";

import "./autocomplete.css";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const context = useContext(AppContext);

  const [ucdnBalance, setUcdnBalance] = useState(0);
  const [qencodeBalance, setQencodeBalance] = useState(0);
  const [ucdnUpdateBalanceDate, setUcdnUpdateBalanceDate] = useState("");
  const [qencodeUpdateBalanceDate, setQencodeUpdateBalanceDate] = useState("");
  const [postCount, setPostCount] = useState([]);
  const [currency, setCurrency] = React.useState({ code: "eur", symbol: "€" });
  const [currencies, setCurrencies] = React.useState([]);
  const [activeSubscriptionsCount, setActiveSubscriptionsCount] = useState([]);
  const [transactionsCounters, setTransactionsCounters] = useState({});
  const [incomeSource, setIncomeSource] = useState("All");
  const [incomes, setIncomes] = useState({});
  const [pspSource, setPspSource] = useState("All");
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const [monthFilter, setMonthFilter] = useState(new Date().getMonth() + 1);
  const [modelName, setModelName] = useState("");
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [countModel, setCountModel] = useState(0);
  const [countGodfather, setCountGodfather] = useState(0);
  const [countPromoters, setCountPromoters] = useState(0);
  const [countSuperGodFathers, setCountSuperGodFathers] = useState(0);
  const [countAgency, setCountAgency] = useState(0);
  const [countAsso, setCountAsso] = useState(0);
  const [countFan, setCountFan] = useState(0);
  const [countModelDeleted, setCountModelDeleted] = useState(0);
  const [countFanDeleted, setCountFanDeleted] = useState(0);
  // const [countAmbassador, setCountAmbassador] = useState(0);
  const [countNetworks, setCountNetworks] = useState(0);
  const [updateSent, setUpdateSent] = useState(false);
  const [clients, setClients] = useState(false);

  useEffect(() => {
    const authService = new AuthService();

    // Get all clients connected
    authService.setUrl(context.apiUrl + context.liveNotifierPath + "clients");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setClients(data.clients ? data.clients : 0);
    });

    // Get all users
    authService.setUrl(context.apiUrl + context.usersHonoPath + "counters");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setCountFan(data.fan);
      setCountModel(data.model);
      setCountGodfather(data.godfather);
      setCountPromoters(data.promoter);
      setCountAgency(data.agency);
      setCountSuperGodFathers(data.superGodFather);
      setCountAsso(data.asso);
      setCountFanDeleted(data.fanDeleted);
      setCountModelDeleted(data.modelDeleted);
    });

    // Get all ambassadors
    // authService.setUrl(context.apiUrl + context.ambassadorsPath + "all");
    // authService.request("get", {}, response => {
    //   const { data } = response;
    //   const listAmbasssadors = data.map(ambassador => ambassador);
    //   setCountAmbassador(listAmbasssadors.length);
    // });

    // Get UCDN balance
    authService.setUrl(
      context.apiUrl + context.serverStatusPath + "thirdparty/balance"
    );
    authService.request("get", { name: "ucdn" }, (response) => {
      const { data } = response;
      setUcdnBalance(data.balance);
      setUcdnUpdateBalanceDate(data.date);
    });

    // Get Qencode balance
    authService.setUrl(
      context.apiUrl + context.serverStatusPath + "thirdparty/balance"
    );
    authService.request("get", { name: "qencode" }, (response) => {
      const { data } = response;
      setQencodeBalance(data.balance);
      setQencodeUpdateBalanceDate(data.date);
    });

    // Get all social network
    authService.setUrl(context.apiUrl + context.socialNetworksPath);
    authService.request("get", {}, (response) => {
      const { data } = response;
      if (data && Array.isArray(data)) {
        let count = data.map((e) => e.followers).reduce((a, b) => a + b);
        setCountNetworks(numberWithSpaces(Math.round(count)));
      }
    });

    // Get post count
    authService.setUrl(context.apiUrl + context.postsHonoPath + "count");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setPostCount(data.count);
    });

    getSubscribers();
  }, []);

  const getSubscribers = () => {
    const authService = new AuthService();
    // Get subscriptions
    authService.setUrl(context.apiUrl + context.subscribeHonoPath + "counters");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setActiveSubscriptionsCount(data.count);
    });
  };

  const updateSocialNetwork = () => {
    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.socialNetworksPath);
    authService.request("put", {}, (response) => {});
    setUpdateSent(true);
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    getAllIncomes();
    getTransactionsCounters();
  }, [pspSource, incomeSource, modelName, monthFilter, yearFilter, currency]);

  const getCurrencies = () => {
    const authService = new AuthService();

    authService.setUrl(
      context.apiUrl + context.categoriesPath + "currencies/all"
    );
    authService.request("get", {}, (response) => {
      const { data } = response;
      if (data && Array.isArray(data)) {
        setCurrencies(data);
      }
    });
  };

  const getAllIncomes = () => {
    const authService = new AuthService();

    authService.setUrl(context.apiUrl + context.transactionsPath + "incomes");
    authService.request(
      "get",
      {
        psp: pspSource,
        month: monthFilter ? monthFilter : "All",
        year: yearFilter ? yearFilter : "All",
        currency: currency.code,
      },
      (response) => {
        const incomes = response.data;
        setIncomes(incomes);
      }
    );
  };

  const getTransactionsCounters = () => {
    const authService = new AuthService();

    authService.setUrl(context.apiUrl + context.transactionsPath + "counters");
    authService.request(
      "get",
      {
        income: incomeSource,
        psp: pspSource,
        username: modelName,
        month: monthFilter ? monthFilter : "All",
        year: yearFilter ? yearFilter : "All",
        currency: currency.code,
      },
      (response) => {
        var transactionsCounters = response.data;
        if (transactionsCounters) {
          setTransactionsCounters(transactionsCounters);
        }
      }
    );
  };

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleIncomeSourceChange = (event) => {
    setIncomeSource(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    let index = currencies.map((e) => e.code).indexOf(event.target.value);
    setCurrency(currencies[index]);
  };

  const handlePspSourceChange = (event) => {
    setPspSource(event.target.value);
  };

  const handleYearChange = (event) => {
    setYearFilter(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonthFilter(event.target.value);
  };

  const handleModelNameChange = (event) => {
    var userName = event.target.value;
    if (userName != undefined) {
      const authService = new AuthService();

      setModelName(userName);

      // Get all categories
      authService.setUrl(context.apiUrl + context.usersHonoPath + "suggestion");
      authService.request(
        "get",
        {
          name: userName,
          show: "all",
          age: "[18,60]",
          distance: 100,
        },
        (response) => {
          const { data } = response;
          setModelSuggestions(data);
        }
      );
    }
  };

  // When the input is focused, Autosuggest will consult this function when to render suggestions.
  // Use it, for example, if you want to display suggestions when input value is at least 2 characters long.
  const shouldRenderSuggestions = (value) => {
    if (value && value.length >= 1) {
      return true;
    }
  };

  const onSuggestionSelected = (event, data) => {
    setModelName(data.suggestionValue);
  };

  const inputProps = {
    placeholder: "Model name",
    onChange: handleModelNameChange,
    value: modelName,
  };

  var date = new Date();

  const {
    buyPrivateMedia,
    subscription,
    meeting,
    tip,
    refund,
    recharge,
    total,
    count,
  } = transactionsCounters;

  let incomeChatMedia = buyPrivateMedia ? buyPrivateMedia.total : 0;
  let nbChatMediaTransactions = buyPrivateMedia ? buyPrivateMedia.count : 0;
  let incomeLives = meeting ? meeting.total : 0;
  let nbLiveTransactions = meeting ? meeting.count : 0;
  let incomeSubscription = subscription ? subscription.total : 0;
  let nbSubscriptionCount = subscription ? subscription.count : 0;
  let incomeTip = tip ? tip.total : 0;
  let nbTipCount = tip ? tip.count : 0;
  let incomeRecharge = recharge ? recharge.total : 0;
  let nbRechargeCount = recharge ? recharge.count : 0;
  let incomeRefund = refund ? refund.total : 0;
  let nbRefunds = refund ? refund.count : 0;
  let totalIncome = total ? total : 0;
  let totalTransactions = count ? count : 0;
  let startingDate = 2020;
  let yearList = ["All"];
  for (let i = startingDate; i <= new Date().getFullYear(); ++i) {
    yearList.push(i);
  }

  let monthList = [
    "All",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  return (
    <div>
      {/* <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountBalance />
              </CardIcon>
              <p className={classes.cardCategory}>UCDN</p>
              <h3 className={classes.cardTitle}>{ucdnBalance} $</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {ucdnUpdateBalanceDate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountBalance />
              </CardIcon>
              <p className={classes.cardCategory}>Qencode</p>
              <h3 className={classes.cardTitle}>{qencodeBalance} $</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {qencodeUpdateBalanceDate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}

      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Fans</p>
              <h3 className={classes.cardTitle}>{countFan}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Models</p>
              <h3 className={classes.cardTitle}>{countModel}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Godfathers</p>
              <h3 className={classes.cardTitle}>{countGodfather}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Promoters</p>
              <h3 className={classes.cardTitle}>{countPromoters}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Agencies</p>
              <h3 className={classes.cardTitle}>{countAgency}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Super godfathers</p>
              <h3 className={classes.cardTitle}>{countSuperGodFathers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Asso</p>
              <h3 className={classes.cardTitle}>{countAsso}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Fans deleted</p>
              <h3 className={classes.cardTitle}>{countFanDeleted}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Models deleted</p>
              <h3 className={classes.cardTitle}>{countModelDeleted}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Photo />
              </CardIcon>
              <p className={classes.cardCategory}>Posts</p>
              <h3 className={classes.cardTitle}>{postCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Loyalty />
              </CardIcon>
              <p className={classes.cardCategory}>Subscriptions</p>
              <h3 className={classes.cardTitle}>{activeSubscriptionsCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Ambassadors</p>
              <h3 className={classes.cardTitle}>{countAmbassador}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <BubbleChart />
              </CardIcon>
              <p className={classes.cardCategory}>Social Networks</p>
              <Box display="flex" justifyContent="flex-end">
                {!updateSent && (
                  <Button
                    className={classes.textButton}
                    onClick={updateSocialNetwork}
                    variant="outlined"
                    color="primary"
                  >
                    Update
                  </Button>
                )}
                {updateSent && <h3 className={classes.sentText}>Sent...</h3>}
                <h3 className={classes.cardTitle}>{countNetworks}</h3>
              </Box>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AccountCircle />
              </CardIcon>
              <p className={classes.cardCategory}>Conected users</p>
              <h3 className={classes.cardTitle}>{clients}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <FormControl className={classes.formControl}>
        <InputLabel>PSP</InputLabel>
        <Select onChange={handlePspSourceChange} value={pspSource}>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="securionpay">Securion Pay</MenuItem>
          <MenuItem value="epoch">Epoch</MenuItem>
          {/* <MenuItem value="opp">OPP</MenuItem>
          <MenuItem value="stripe">Stripe</MenuItem> */}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Currency</InputLabel>
        <Select onChange={handleCurrencyChange} value={currency.code}>
          {currencies.map((currency) => (
            <MenuItem value={currency.code}>{currency.code}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Année</InputLabel>
        <Select onChange={handleYearChange} value={yearFilter}>
          {yearList.map((year) => (
            <MenuItem value={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Mois</InputLabel>
        <Select onChange={handleMonthChange} value={monthFilter}>
          {monthList.map((month, index) => (
            <MenuItem value={index}>{month}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <GridContainer>
        {Object.keys(incomes).map((key) => (
          <GridItem xs={6} sm={4} md={2} lg={2}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="warning" style={{ background: "#D4AF37" }}>
                  <p
                    style={{
                      fontSize: "30px",
                      lineHeight: "30px",
                      fontWeight: "bold",
                      margin: "0px",
                    }}
                  >
                    {currency.symbol}
                  </p>
                </CardIcon>
                <p className={classes.cardCategory}>{key}</p>
                <h3 className={classes.cardTitle}>{incomes[key]}</h3>
              </CardHeader>
            </Card>
          </GridItem>
        ))}
      </GridContainer>

      <FormControl className={classes.formControl}>
        <InputLabel>Income</InputLabel>
        <Select onChange={handleIncomeSourceChange} value={incomeSource}>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="platform">Platform</MenuItem>
          <MenuItem value="models">Models</MenuItem>
          <MenuItem value="godfathers">Godfathers</MenuItem>
          <MenuItem value="promoters">Promoters</MenuItem>
          <MenuItem value="agencies">Agencies</MenuItem>
          <MenuItem value="superGodFathers">Super godfathers</MenuItem>
          <MenuItem value="bank">Bank</MenuItem>
          <MenuItem value="wallets">Wallets</MenuItem>
          <MenuItem value="bonus">Bonus</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Autosuggest
          suggestions={modelSuggestions.filter(
            (e) => e?.toLowerCase().indexOf(modelName?.toLowerCase()) !== -1
          )}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={inputProps}
        />
      </FormControl>

      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <p className={classes.cardCategory}>
                Transactions ({totalTransactions})
              </p>
              <h3 className={classes.cardTitle}>
                <span className={classes.titleHighlighted}>
                  {totalIncome} {currency.symbol}
                </span>
              </h3>
            </CardHeader>
            <CardBody>
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Type", "Incomes"],
                  [
                    "Médias chat " + incomeChatMedia + " " + currency.symbol,
                    incomeChatMedia,
                  ],
                  [
                    "Abonnements " + incomeSubscription + " " + currency.symbol,
                    incomeSubscription,
                  ],
                  ["Lives " + incomeLives + " " + currency.symbol, incomeLives],
                  ["Tip " + incomeTip + " " + currency.symbol, incomeTip],
                  [
                    "Recharge " + incomeRecharge + " " + currency.symbol,
                    incomeRecharge,
                  ],
                ]}
                options={{
                  title: "Transactions",
                  legend: "none",
                  pieHole: 0.4,
                  slices: {
                    0: { color: "#00acc1" },
                    1: { color: "#ed4749" },
                    2: { color: "purple" },
                    3: { color: "#30a658" },
                    4: { color: "#ec407a" },
                  },
                }}
                rootProps={{ "data-testid": "3" }}
              />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <CreditCardIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                Abonnements ({nbSubscriptionCount})
              </p>
              <h3 className={classes.cardTitle}>
                {incomeSubscription} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>

          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <MmsIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                Médias Chat ({nbChatMediaTransactions})
              </p>
              <h3 className={classes.cardTitle}>
                {incomeChatMedia} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>

          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <MmsIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                Lives ({nbLiveTransactions})
              </p>
              <h3 className={classes.cardTitle}>
                {incomeLives} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>

          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <RedeemIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Dons ({nbTipCount})</p>
              <h3 className={classes.cardTitle}>
                {incomeTip} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>

          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <WalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                Recharge ({nbRechargeCount})
              </p>
              <h3 className={classes.cardTitle}>
                {incomeRecharge} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>

          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <BlockOutlined />
              </CardIcon>
              <p className={classes.cardCategory}>Refunds ({nbRefunds})</p>
              <h3 className={classes.cardTitle}>
                {incomeRefund} {currency.symbol}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Updated at {date.toLocaleString()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

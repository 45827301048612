import React, { useContext, useEffect, useState } from "react";

import Movepoint from "components/Movepoint/Movepoint.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { pink, green, orange } from "@material-ui/core/colors";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import iconCertification from "../../img/icon_certification.png";

// import Avatar from "./Avatar.js";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  formControl: {
    margin: "5px",
    width: "180px",
  },
  avatar: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    marginTop: "10px",
    marginRight: "10px",
    height: "30px",
  },
  successButton: {
    color: "#fff",
    marginTop: "10px",
    marginRight: "10px",
    background: green[500],
  },
  warningButton: {
    color: "#FFF",
    marginTop: "10px",
    marginRight: "10px",
    background: orange[500],
  },
  dangerButton: {
    color: "#fff",
    marginTop: "10px",
    marginRight: "10px",
    background: pink[500],
  },
  paper: {
    position: "relative",
    height: "100%",
    width: "100%",
    height: "350px",
    overflow: "hidden",
  },
  iconCertification: {
    position: "absolute",
    top: "5px",
    left: "5px",
    height: "35px",
  },
};

const useStyles = makeStyles(styles);

export default function List(props) {
  const classes = useStyles();
  const context = useContext(AppContext);

  const authService = new AuthService();
  const nbItemsToFetch = 12;

  const [toggle, setToggle] = useState(false);
  const [items, setItems] = useState([]);

  const { verified } = props;

  useEffect(() => {
    loadItems(0, nbItemsToFetch);
  }, []);

  const loadItems = (start, end) => {
    authService.setUrl(
      context.apiUrl + context.usersHonoPath + "profilPicture"
    );
    authService.request(
      "get",
      {
        start,
        end,
        verified: verified ? verified : false,
      },
      (response) => {
        setItems(items.concat(response.data));
      }
    );
  };

  const showMore = () => {
    let start = items.length;
    let end = start + nbItemsToFetch;
    loadItems(start, end);
  };

  const handleResponse = (picture, status) => {
    clearItem(picture);
    authService.setUrl(
      context.apiUrl + context.usersPath + "profilPicture/verified"
    );
    authService.request(
      "put",
      {
        userId: picture.userId,
        status,
      },
      () => {}
    );
  };

  const clearItem = (item) => {
    let index = items.map((e) => e._id).indexOf(item._id);
    items.splice(index, 1);
    setItems(items);
    setToggle(!toggle);
  };

  return (
    <>
      <Grid container xs={12} spacing={3}>
        {items.map((picture) => (
          <Grid key={picture.userId} item lg={3} md={3} xs={12}>
            <>
              <Paper className={classes.paper}>
                {picture.userVerified && (
                  <img
                    className={classes.iconCertification}
                    src={iconCertification}
                  />
                )}
                <img className={classes.avatar} src={picture.normal} />
              </Paper>

              <p>
                <b>{picture.userName}</b>
              </p>
              <p>
                Unsafe coefficient :{" "}
                {picture.unsafeCoefficient
                  ? Math.round(picture.unsafeCoefficient * 100)
                  : " UNK "}
              </p>

              <Box p={0} display="flex" flexDirection="column">
                {!verified && (
                  <Button
                    className={classes.successButton}
                    onClick={() => handleResponse(picture, "approved")}
                    variant="contained"
                  >
                    Accept
                  </Button>
                )}

                {!verified && (
                  <Button
                    className={classes.warningButton}
                    onClick={() => handleResponse(picture, "nudity")}
                    variant="contained"
                  >
                    Nudity
                  </Button>
                )}

                <Button
                  className={classes.dangerButton}
                  onClick={() => handleResponse(picture, "refused")}
                  variant="contained"
                >
                  Refuse
                </Button>
              </Box>
            </>
          </Grid>
        ))}
      </Grid>

      <Movepoint onEnter={showMore} />
    </>
  );
}

import React, { useEffect, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { grey, green, orange } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CachedIcon from "@material-ui/icons/Cached";
import AdjustIcon from "@material-ui/icons/Adjust";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  boxColumn: {
    minWidth: 140,
  },
  paper: {
    height: "auto",
    width: "100%",
    padding: 10,
    overlow: "scroll",
  },
  textCenter: {
    textAlign: "center",
  },
  acceptButton: {
    background: "#87ceeb",
    color: "white",
    minWidth: "100px",
  },
  dangerButton: {
    background: "#d62546",
    color: "white",
    minWidth: "100px",
  },
};

const useStyles = makeStyles(styles);

export default function List(props) {
  const context = useContext(AppContext);
  const classes = useStyles();

  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(5);
  const [toggle, setToggle] = React.useState(true);
  const [posts, setPosts] = React.useState([]);
  const [rowExpandedIndex, setRowExpandedIndex] = React.useState(-1);
  const [contentCategories, setContentCategories] = React.useState([]);

  const { mediaType, source, username, allowToDelete } = props;

  useEffect(() => {
    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.categoriesPath + "content");
    authService.request("get", {}, (response) => {
      if (response.data) {
        setContentCategories(response.data.map((e) => e._id));
      }
    });
  }, []);

  useEffect(() => {
    const authService = new AuthService();
    const categories = JSON.stringify(contentCategories);

    if (source === "post") {
      const postsData = {
        approved: props.approved,
        blurBrokenOnly: false,
        start,
        end,
        categories,
        username: username ? username : "all",
        mediaType,
        future: true
      }
  
      authService.setUrl(context.apiUrl + context.postsHonoPath + "all");
      authService.request("get", postsData, response => {
        if (Array.isArray(response.data)) {
          setPosts(response.data);
        }
      });
    } else if (source === "chat") {
      const messageData = {
        start,
        end,
        mediaType,
        username,
      };

      authService.setUrl(context.apiUrl + context.messagesHonoPath + "upload");
      authService.request("get", messageData, (response) => {
        if (Array.isArray(response.data)) {
          setPosts(response.data);
        }
      });
    }
  }, [contentCategories, toggle, end]);

  const deletePost = (post, index) => {
    const authService = new AuthService(context.apiUrl + context.postsPath);
    authService.request("delete", { postId: post._id }, () => {
      var newPosts = posts;
      newPosts.splice(index, 1);
      setPosts(newPosts);
      setToggle(!toggle);
    });
  };

  return (
    <>
      {posts.length === 0 && (
        <p className={classes.textCenter}>Aucun upload...</p>
      )}

      {posts &&
        posts.map((post, index) => (
          <>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ overflowY: "auto" }}
            >
              {allowToDelete && (
                <Button
                  className={classes.dangerButton}
                  onClick={() => deletePost(post, index)}
                  variant="contained"
                >
                  Delete
                </Button>
              )}

              <Box m={1} className={classes.boxColumn}>
                <p style={{ color: grey[500] }}>
                  {new Date(post.date).toLocaleString()}
                </p>
              </Box>

              <Box m={1} className={classes.boxColumn}>
                <p style={{ color: grey[500] }}>{post.userName}</p>
              </Box>

              <Box m={1}>
                <p>{post.userId}</p>
              </Box>

              <Box m={1}>
                <p>{post._id}</p>
              </Box>

              {post.videoId && (
                <Box m={1}>
                  <p>{post.videoId}</p>
                </Box>
              )}

              {post.photoId && (
                <Box m={1}>
                  <p>{post.photoId}</p>
                </Box>
              )}

              {/* Upload in finished */}
              {post.details && post.details.partSha1Array && (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {/* Upload successfull */}
                  {post.details.partSha1Array.map((part, index) => (
                    <Tooltip title={`Upload part ${index + 1}`}>
                      <Box>
                        <CheckCircleOutlineIcon style={{ color: green[800] }} />
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              )}

              {/* Upload in pregress */}
              {post.details &&
                post.details.parts &&
                post.details.parts.count > 0 && (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {/* Uploads successfull */}
                    {[...Array(post.details.parts.uploaded)].map(
                      (part, index) => (
                        <Tooltip title={`Upload part ${index + 1}`}>
                          <Box>
                            <CheckCircleOutlineIcon
                              style={{ color: green[800] }}
                            />
                          </Box>
                        </Tooltip>
                      )
                    )}
                    {/* Uploads pending */}
                    {[
                      ...Array(
                        post.details.parts.count - post.details.parts.uploaded
                      ),
                    ].map((part, index) => (
                      <Tooltip
                        title={`Upload part ${post.details.parts.uploaded +
                          index +
                          1}`}
                      >
                        <Box>
                          {index === 0 && (
                            <CachedIcon style={{ color: orange[500] }} />
                          )}
                          {index > 0 && (
                            <AdjustIcon style={{ color: grey[500] }} />
                          )}
                        </Box>
                      </Tooltip>
                    ))}
                  </Box>
                )}

              <Box>
                {post.pending &&
                  post.details.parts &&
                  post.status === "upload" && (
                    <>
                      <Tooltip title="Publish">
                        <AdjustIcon style={{ color: grey[500] }} />
                      </Tooltip>
                      <Tooltip title="Transcoding">
                        <AdjustIcon style={{ color: grey[500] }} />
                      </Tooltip>
                    </>
                  )}

                {post.pending &&
                  post.details.parts &&
                  post.status === "uploaded" && (
                    <Tooltip title="Transcoding">
                      <CachedIcon style={{ color: orange[800] }} />
                    </Tooltip>
                  )}

                {post.pending &&
                  !post.details.parts &&
                  post.status === "upload" && (
                    <>
                      <Tooltip title="Publish">
                        <CachedIcon style={{ color: orange[800] }} />
                      </Tooltip>
                    </>
                  )}

                {!post.pending && (
                  <Tooltip title="Publish">
                    <CheckCircleOutlineIcon style={{ color: green[800] }} />
                  </Tooltip>
                )}
              </Box>

              {post.details && post.details.partSha1Array && (
                <>
                  <Box>
                    {post.details.transcoded && (
                      <Tooltip title="Transcoding">
                        <CheckCircleOutlineIcon style={{ color: green[800] }} />
                      </Tooltip>
                    )}

                    {!post.details.transcoded && (
                      <Tooltip title="Transcoding">
                        <CachedIcon style={{ color: orange[800] }} />
                      </Tooltip>
                    )}
                  </Box>
                </>
              )}

              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() =>
                  setRowExpandedIndex(rowExpandedIndex !== index ? index : -1)
                }
              >
                {rowExpandedIndex === index ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </Box>

            <Collapse in={rowExpandedIndex === index}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {post && post.video && (
                  <Box>
                    Video :{" "}
                    <a target="_blank" href={post.video}>
                      {post.video.split("/").slice(-1)}
                    </a>
                  </Box>
                )}

                {post && post.src && post.src.normal && (
                  <Box>
                    Normal :{" "}
                    <a target="_blank" href={post.src.normal}>
                      {post.src.normal.split("/").slice(-1)}
                    </a>
                  </Box>
                )}

                {post && post.src && post.src.medium && (
                  <Box>
                    Medium :{" "}
                    <a target="_blank" href={post.src.medium}>
                      {post.src.medium.split("/").slice(-1)}
                    </a>
                  </Box>
                )}

                {post && post.src && post.src.small && (
                  <Box>
                    Small :{" "}
                    <a target="_blank" href={post.src.small}>
                      {post.src.small.split("/").slice(-1)}
                    </a>
                  </Box>
                )}
              </Box>
            </Collapse>
          </>
        ))}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          className={classes.acceptButton}
          onClick={() => setEnd(end + 5)}
          variant="contained"
        >
          See more
        </Button>

        <Box marginLeft={1}>
          <Button
            className={classes.acceptButton}
            onClick={() => setToggle(!toggle)}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Box>
    </>
  );
}

import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Autosuggest from "react-autosuggest";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    width: "100%",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    margin: "40px 0",
  },
  flexStart: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "320px",
    margin: "0 auto",
  },
  flexCenter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "20px",
  },
  autoSuggest: {
    position: "relative",
    zIndex: "3",
    margin: "0 auto",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    height: "30px",
    width: "100%",
  },
  textBold: {
    fontWeight: "bold",
  },
  statusMessage: {
    fontWeight: "bold",
    textAlign: "center",
  },
  errorMessage: {
    fontWeight: "bold",
    textAlign: "center",
    color: "#f44336",
  },
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = [];

let staringDate = 2020;
let date = new Date();
for (let year = staringDate; year <= date.getFullYear(); ++year) {
  years.push(year);
}

export default function Invoices() {
  const context = useContext(AppContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const authService = new AuthService();

  // User
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  // Filter by Username
  const [userNameInput, setUserNameInput] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameSuggestions, setUserNameSuggestions] = useState([]);

  // Filter by Date
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());

  // Message API
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const userNameInputProps = {
    placeholder: "User Name",
    onChange: (event) => handleUserNameChange(event),
    value: userNameInput,
    style: {
      padding: "17.75px 20px",
      width: "315px",
      boxSizing: "border-box",
    },
  };

  const onSuggestionSelected = (name) => {
    setUserNameInput(name);
    setUserName(name);
    getUsersByUsername(name);
  };

  const handleUserNameChange = (event) => {
    let username = event.target.value;
    if (username != undefined) {
      setUserNameInput(username);
      if (userName) {
        setUserName("");
        setUserId("");
        handleSetStatusMessage("");
      }

      authService.setUrl(context.apiUrl + context.usersHonoPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setUserNameSuggestions(data);
        }
      );
    }
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    handleSetStatusMessage("");
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    handleSetStatusMessage("");
  };

  const handleSetErrorMessage = (message) => {
    setStatusMessage("");
    setErrorMessage(message);
  };

  const handleSetStatusMessage = (message) => {
    setErrorMessage("");
    setStatusMessage(message);
  };

  const getUsersByUsername = async (userName) => {
    authService.setUrl(context.apiUrl + context.usersHonoPath + "userName");
    authService.request(
      "get",
      {
        userName,
      },
      (response) => {
        const { data } = response;
        console.log(data);
        if (!data || data?.error) {
          handleSetErrorMessage(data?.error || "Error");
          setUserName("");
        } else {
          setUsers(data);
        }
      }
    );
  };

  const handleGenerateInvoice = async (userId) => {
    setUserId(userId);
    handleSetStatusMessage("Invoice generation...");

    authService.setUrl(context.apiUrl + context.invoicePath + "summup");
    authService.request(
      "post",
      {
        month: (month + 1).toString(),
        year: year.toString(),
        userId,
      },
      (response) => {
        const { data } = response;
        if (!data || data.error) {
          handleSetErrorMessage("Invoice generation error");
        } else {
          handleSetStatusMessage("Invoice successfully generated");
        }
      }
    );
  };

  const handleDownloadInvoice = async (userId) => {
    setUserId(userId);
    authService.setResponseType("blob");
    authService.setUrl(context.apiUrl + context.invoicePath + "summup");

    handleSetStatusMessage("Download invoice...");

    // Download invoice file
    authService.request(
      "get",
      {
        month: (month + 1).toString(),
        year: year.toString(),
        modelId: userId,
        source: "model",
        position: "sender",
      },
      (response) => {
        const { data } = response;
        if (!data || data.size < 100 || data.error) {
          handleSetErrorMessage("Invoice not available.");
        } else {
          let blob = new Blob([data], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          window.open(url);
          handleSetStatusMessage("");
        }
      }
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Invoices</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all about Invoices
              </p>
            </CardHeader>
            <CardBody>
              <div className={classes.flexColumn}>
                <div className={classes.autoSuggest}>
                  <Autosuggest
                    suggestions={userNameSuggestions?.filter(
                      (e) =>
                        e
                          ?.toLowerCase()
                          .indexOf(userNameInput?.toLowerCase()) !== -1
                    )}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionSelected={(event, data) =>
                      onSuggestionSelected(data.suggestionValue)
                    }
                    getSuggestionValue={(suggestion) => suggestion}
                    shouldRenderSuggestions={() => true}
                    renderSuggestion={(elmt) => <span>{elmt}</span>}
                    inputProps={userNameInputProps}
                  />
                </div>

                {userName && (
                  <>
                    <div className={classes.flexStart}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Month</InputLabel>
                        <Select onChange={handleMonthChange} value={month}>
                          {monthNames.map((m, index) => (
                            <MenuItem key={m} value={index}>
                              {m}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel>Year</InputLabel>
                        <Select onChange={handleYearChange} value={year}>
                          {years.map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={classes.flexCenter}>
                      {users?.map((user, index) => (
                        <Card
                          key={index}
                          style={{
                            margin: "0px",
                            width: "calc(33.3% - 20px)",
                          }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              color="textSecondary"
                              noWrap
                            >
                              {user?._id}
                            </Typography>
                            <Typography noWrap>
                              FirstName :{" "}
                              <span className={classes.textBold}>
                                {user?.firstName}
                              </span>
                            </Typography>
                            <Typography noWrap>
                              Mail :{" "}
                              <span className={classes.textBold}>
                                {user?.mail}
                              </span>
                            </Typography>
                            <Typography noWrap>
                              Deleted :{" "}
                              <span className={classes.textBold}>
                                {user.deleted ? "Yes" : "No"}
                              </span>
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              className={classes.infoButton}
                              onClick={() => handleGenerateInvoice(user._id)}
                              variant="contained"
                            >
                              Generate
                            </Button>

                            <Button
                              className={classes.infoButton}
                              onClick={() => handleDownloadInvoice(user._id)}
                              variant="contained"
                            >
                              Download
                            </Button>
                          </CardActions>

                          {userId === user._id && statusMessage && (
                            <p className={classes.statusMessage}>
                              {statusMessage}
                            </p>
                          )}

                          {userId === user._id && errorMessage && (
                            <p className={classes.errorMessage}>
                              {errorMessage}
                            </p>
                          )}
                        </Card>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/*eslint-disable*/
import React, { useEffect, useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import Autosuggest from "react-autosuggest";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  formControl: {
    margin: "5px",
    width: "180px",
  },
  textField: {
    width: "750px",
  },
  textArea: {
    marginTop: "10px",
    width: "750px",
  },
  sendButton: {
    marginTop: "20px",
    background: "#87ceeb",
  },
};

const useStyles = makeStyles(styles);

export default function Notifications() {
  const context = useContext(AppContext);
  const classes = useStyles();
  const [tr, setTr] = useState({});
  const [countries, setCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState("All");
  const [userTypes, setUserTypes] = useState([]);
  const [userSubTypes, setUserSubTypes] = useState([]);
  const [userTypeSelected, setUserTypeSelected] = useState("All");
  const [userSubTypeSelected, setUserSubTypeSelected] = useState("All");
  const [sexSelected, setSexSelected] = useState("All");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [username, setUserName] = useState("");
  const [mailOption, setMailOption] = useState(false);
  const [notificationOption, setNotificationOption] = useState(false);
  const [
    registrationTimeRangeActivated,
    setRegistrationTimeRangeActivated,
  ] = useState(false);
  const [registrationRangeStart, setRegistrationRangeStart] = useState(
    new Date()
  );
  const [registrationRangeEnd, setRegistrationRangeEnd] = useState(new Date());
  const [minFollowers, setMinFollowers] = useState(0);
  const [maxFollowers, setMaxFollowers] = useState(10000);
  const [minSubscribers, setMinSubscribers] = useState(0);
  const [maxSubscribers, setMaxSubscribers] = useState(10000);

  const sexes = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Women",
      label: "Women",
    },
    {
      value: "Man",
      label: "Man",
    },
    {
      value: "Non gendered",
      label: "Non gendered",
    },
  ];

  const defaultCategories = {
    _id: "All",
    keyname: "all",
  };

  useEffect(() => {
    const authService = new AuthService();

    // Get translations
    authService.setUrl(context.apiUrl + context.translationsPath);
    authService.request("get", { country: "EN", type: "site" }, (response) => {
      const { data } = response;
      if (data.length > 0) {
        data[0].translations.userOption.all = "All";
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }
    });

    // Get user categories
    authService.setUrl(context.apiUrl + context.categoriesPath + "user");
    authService.request("get", {}, (response) => {
      const { data } = response;
      console.log([defaultCategories].concat(data));
      setUserTypes([defaultCategories].concat(data));
    });

    // Get All countries
    authService.setUrl(context.apiUrl + context.geolocationPath + "countries");
    authService.request("get", {}, (response) => {
      const { data } = response;
      setCountries(["All"].concat(data));
    });
  }, []);

  const handleUserTypeChange = (evt) => {
    const authService = new AuthService();
    let { value } = evt.target;
    setUserTypeSelected(value);
    authService.setUrl(context.apiUrl + context.categoriesPath + "user");
    authService.request("get", { parent: value }, (response) => {
      const { data } = response;
      console.log(JSON.stringify(data, null, 4));
      setUserSubTypes([defaultCategories].concat(data));
    });
  };

  const handleUserSubTypeChange = (evt) => {
    let { value } = evt.target;
    setUserSubTypeSelected(value);
  };

  const handleFromDateChange = (date) => {
    setRegistrationRangeStart(date);
  };

  const handleToDateChange = (date) => {
    setRegistrationRangeEnd(date);
  };

  const handleMinFollowersChange = (evt) => {
    let value = evt.target.value;
    setMinFollowers(value);
  };

  const handleMaxFollowersChange = (evt) => {
    let value = evt.target.value;
    setMaxFollowers(value);
  };

  const handleMinSubscribersChange = (evt) => {
    let value = evt.target.value;
    setMinSubscribers(value);
  };

  const handleMaxSubscribersChange = (evt) => {
    let value = evt.target.value;
    setMaxSubscribers(value);
  };

  const sendNotification = () => {
    if (mailOption || notificationOption) {
      if (message) {
        if (subject || !mailOption) {
          const data = {
            userType:
              userSubTypeSelected !== "All"
                ? userSubTypeSelected
                : userTypeSelected,
            sex: sexSelected,
            country: countrySelected,
            subject,
            message,
            username: username ? username : "All",
            registrationRangeStart: registrationTimeRangeActivated
              ? registrationRangeStart
              : "",
            registrationRangeEnd: registrationTimeRangeActivated
              ? registrationRangeEnd
              : "",
            minFollowers: Number(minFollowers),
            maxFollowers: Number(maxFollowers),
            minSubscribers: Number(minSubscribers),
            maxSubscribers: Number(maxSubscribers),
          };

          const authService = new AuthService();

          if (mailOption) {
            authService.setUrl(context.apiUrl + context.mailPath + "text");
            authService.request("post", data, (response) => {
              const { data } = response;
              setSubject("");
              setMessage("");
              alert(data.message);
            });
          }

          if (notificationOption) {
            authService.setUrl(
              context.apiUrl + context.notificationsHonoPath + "text"
            );
            delete data.subject;
            authService.request("post", data, (response) => {
              const { data } = response;
              setMessage("");
              alert(data.message);
            });
          }
        } else {
          alert("Subject is empty");
        }
      } else {
        alert("Message is empty");
      }
    } else {
      alert("Please, select a least one transport option.");
    }
  };

  const handleUserNameChange = (event) => {
    var username = event.target.value;
    if (username != undefined) {
      const authService = new AuthService();

      setUserName(username);

      authService.setUrl(context.apiUrl + context.usersHonoPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: countrySelected,
          sex: sexSelected,
          userType:
            userSubTypeSelected !== "All"
              ? userSubTypeSelected
              : userTypeSelected,
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setModelSuggestions(data);
        }
      );
    }
  };

  const onSuggestionSelected = (event, data) => {
    setUserName(data.suggestionValue);
  };

  const inputProps = {
    placeholder: "User name",
    onChange: handleUserNameChange,
    value: username,
  };

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Notifications</h4>
      </CardHeader>
      <CardBody>
        <FormControl className={classes.formControl}>
          <InputLabel>User Categories</InputLabel>
          <Select onChange={handleUserTypeChange} value={userTypeSelected}>
            {userTypes.map((type) => (
              <MenuItem key={type.keyname} value={type._id}>
                {tr.userOption ? tr.userOption[type.keyname] : ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>User Sub Categories</InputLabel>
          <Select
            onChange={handleUserSubTypeChange}
            value={userSubTypeSelected}
          >
            {userSubTypes.map((type) => (
              <MenuItem key={type.keyname} value={type._id}>
                {tr.userOption[type.keyname]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>Sex</InputLabel>
          <Select
            onChange={(evt) => setSexSelected(evt.target.value)}
            value={sexSelected}
          >
            {sexes.map((sex) => (
              <MenuItem key={sex.value} value={sex.value}>
                {sex.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>Countries</InputLabel>
          <Select
            onChange={(evt) => setCountrySelected(evt.target.value)}
            value={countrySelected}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <Autosuggest
            suggestions={modelSuggestions.filter(
              (e) => e?.toLowerCase().indexOf(username?.toLowerCase()) !== -1
            )}
            onSuggestionsFetchRequested={() => {}}
            onSuggestionsClearRequested={() => {}}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={(suggestion) => suggestion}
            shouldRenderSuggestions={() => true}
            renderSuggestion={(elmt) => <span>{elmt}</span>}
            inputProps={inputProps}
          />
        </FormControl>

        {userTypeSelected !== "fan" && (
          <>
            <FormControl className={classes.formControl}>
              <TextField
                label="Min followers"
                type="number"
                variant="outlined"
                value={minFollowers}
                onChange={handleMinFollowersChange}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                label="Max followers"
                type="number"
                variant="outlined"
                value={maxFollowers}
                onChange={handleMaxFollowersChange}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                label="Min subscribers"
                type="number"
                variant="outlined"
                value={minSubscribers}
                onChange={handleMinSubscribersChange}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                label="Max subscribers"
                type="number"
                variant="outlined"
                value={maxSubscribers}
                onChange={handleMaxSubscribersChange}
              />
            </FormControl>
          </>
        )}
      </CardBody>

      <CardBody>
        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={registrationTimeRangeActivated}
              onChange={() =>
                setRegistrationTimeRangeActivated(
                  !registrationTimeRangeActivated
                )
              }
              color="secondary"
            />
          }
          label="Registration range"
        />
      </CardBody>

      {registrationTimeRangeActivated && (
        <CardBody>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Registration start"
                format="dd/MM/yyyy"
                value={registrationRangeStart}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Registration start"
                format="dd/MM/yyyy"
                value={registrationRangeEnd}
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </CardBody>
      )}

      <CardBody>
        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={mailOption}
              onChange={() => setMailOption(!mailOption)}
              color="secondary"
            />
          }
          label="Mail"
        />

        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={notificationOption}
              onChange={() => setNotificationOption(!notificationOption)}
              color="secondary"
            />
          }
          label="Notification"
        />
      </CardBody>

      {mailOption && (
        <CardBody>
          <TextField
            type="text"
            className={classes.textField}
            placeholder="Subject"
            value={subject}
            onChange={(evt) => setSubject(evt.target.value)}
            variant="outlined"
          />
        </CardBody>
      )}

      <CardBody>
        <TextField
          type="text"
          className={classes.textArea}
          placeholder="Message here..."
          multiline={true}
          rows={3}
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          variant="outlined"
        />
      </CardBody>

      <CardBody>
        <Button
          className={classes.sendButton}
          onClick={sendNotification}
          variant="contained"
        >
          Send
        </Button>
      </CardBody>
    </Card>
  );
}

import React, { useEffect, useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

// core components
import Divider from "@material-ui/core/Divider";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Movepoint from "components/Movepoint/Movepoint.js";
import MessageDetails from "../MessageDetails/MessageDetails.js";

import Warning from "@material-ui/icons/Warning";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  postImgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  postImg: {
    width: "250px",
    height: "250px",
    objectFit: "cover",
  },
  postDetails: {
    width: "100%",
    fontSize: "22px",
  },
  thumb: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  },
  textHeader: {
    padding: "0px 20px 0px 20px",
    color: "#9c35b3",
    fontSize: "18px",
    padding: "0px",
  },
  textButton: {
    color: "#9c35b3",
    cursor: "pointer",
    fontSize: "20px",
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formControlLabel: {
    margin: "5px",
    width: "200px",
    height: "56px",
  },
};

const useStyles = makeStyles(styles);

export default function Chat(props) {
  const nbMessagesByStep = 20;
  const nbConversationsByStep = 12;

  const context = useContext(AppContext);
  const classes = useStyles();

  const [modelUserName, setModelUserName] = React.useState("");
  const [fanUserName, setFanUserName] = React.useState("");
  const [conversations, setConversations] = React.useState([]);
  const [mode, setMode] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [conversationId, setConversationId] = React.useState("");
  const [convStart, setConvStart] = React.useState(0);
  const [convEnd, setConvEnd] = React.useState(nbConversationsByStep);
  const [start, setStart] = React.useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [convProfanityOnly, setConvProfanityOnly] = useState(false);
  const [messageProfanityOnly, setMessageProfanityOnly] = useState(false);

  const authService = new AuthService();

  let timeout = null;

  useEffect(() => {
    setMode("conversation");
    showMoreConversations(
      modelUserName,
      fanUserName,
      convProfanityOnly,
      convStart,
      convEnd,
      false
    );
  }, []);

  function handleClick(conversationId, index) {
    setMode("messages");
    setMessages([]);
    setConversationId(conversationId);
    showMore(0, nbMessagesByStep, conversationId, messageProfanityOnly, true);

    conversations[index].alertSeenByAdmin = true;
    setConversations(conversations);

    authService.setUrl(context.apiUrl + context.conversationsPath + "seen");

    authService.request("post", { conversationId }, () => {
      if (props.onRefresh) {
        props.onRefresh();
      }
    });
  }

  function deleteMessage(messageId) {
    authService.setUrl(context.apiUrl + context.messagesPath);
    authService.request("delete", { messageId }, (response) => {});
  }

  const handleModelUserNameChange = (event) => {
    let { value } = event.target;
    value = value.toLowerCase();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setModelUserName(value);
      setConvStart(0);
      setConvEnd(nbConversationsByStep);
      showMoreConversations(
        value,
        fanUserName,
        convProfanityOnly,
        0,
        nbConversationsByStep,
        true
      );
    }, 300);
  };

  const handleFanUserNameChange = (event) => {
    let { value } = event.target;
    value = value.toLowerCase();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFanUserName(value);
      setConvStart(0);
      setConvEnd(nbConversationsByStep);
      showMoreConversations(
        modelUserName,
        value,
        convProfanityOnly,
        0,
        nbConversationsByStep,
        true
      );
    }, 300);
  };

  const handleConvProfanityOnlyChange = () => {
    setConvProfanityOnly(!convProfanityOnly);
    setConvStart(0);
    setConvEnd(nbConversationsByStep);
    showMoreConversations(
      modelUserName,
      fanUserName,
      !convProfanityOnly,
      0,
      nbConversationsByStep,
      true
    );
  };

  const handleMessageProfanityOnlyChange = () => {
    setMessageProfanityOnly(!messageProfanityOnly);
    setStart(0);
    showMore(0, nbMessagesByStep, conversationId, !messageProfanityOnly, true);
  };

  const showMore = (start, items, conversationId, profanityOnly, reset) => {
    if (conversationId) {
      authService.setUrl(
        context.apiUrl + context.messagesHonoPath + "conversation"
      );
      const page = start + 1;
      setSearchLoading(true);
      authService.request(
        "get",
        {
          id: conversationId,
          profanityOnly,
          page,
          items,
        },
        (response) => {
          let newMessages = reset
            ? response.data
            : messages.concat(response.data);
          let newStart = page;
          setSearchLoading(false);
          setMessages(newMessages);
          setStart(newStart);
        }
      );
    }
  };

  const showMoreConversations = (
    modelUserName,
    fanUserName,
    profanityOnly,
    start,
    end,
    reset
  ) => {
    setSearchLoading(true);
    authService.cancel();
    authService.setUrl(context.apiUrl + context.conversationsHonoPath + "all");
    authService.request(
      "get",
      {
        start,
        end,
        modelUserName,
        fanUserName,
        profanityOnly,
      },
      (response) => {
        let newConversations = reset
          ? response.data
          : conversations.concat(response.data);
        let newStart = newConversations.length;
        setSearchLoading(false);
        setConversations(newConversations);
        setConvStart(newStart);
        setConvEnd(newStart + nbConversationsByStep);
      }
    );
  };

  return (
    <>
      {mode === "messages" && (
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              onChange={handleMessageProfanityOnlyChange}
              checked={messageProfanityOnly}
            />
          }
          label="Show alerts only"
        />
      )}

      {mode === "conversation" && (
        <>
          <TextField
            label="Model"
            type="search"
            variant="outlined"
            onChange={handleModelUserNameChange}
          />

          <TextField
            label="Fan"
            type="search"
            variant="outlined"
            onChange={handleFanUserNameChange}
          />

          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                onChange={handleConvProfanityOnlyChange}
                checked={convProfanityOnly}
              />
            }
            label="Show alerts only"
          />
        </>
      )}

      {mode === "messages" && (
        <p
          onClick={() => setMode("conversation")}
          className={classes.textButton}
        >
          Back to conversations
        </p>
      )}

      {mode === "conversation" && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Chats</h4>
                <p className={classes.cardCategoryWhite}>
                  Here are all the chats
                </p>
              </CardHeader>

              <CardBody>
                <div className={classes.tableHeader}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Model name</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Fan name</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Messages</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Request</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Private</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Last message</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Action</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Alert</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Deleted</label>
                    </GridItem>
                  </GridContainer>
                </div>

                {conversations.map((conv, index) => (
                  <GridContainer key={conv._id + index}>
                    <GridItem xs={12} sm={12} md={2}>
                      <div className={classes.postDetails}>
                        {/* <img className={classes.thumb} src={conv.modelPicture.normal.url} /> */}
                        <p>{conv.modelName}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <div className={classes.postDetails}>
                        <p>{conv.fanName}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <p>{conv.nbMessages}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <p>{conv.nbMediaRequest}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <p>{conv.nbMediaPrivate}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <div className={classes.postDetails}>
                        <p>{new Date(conv.lastUpdate).toLocaleString()}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <p
                          className={classes.textButton}
                          onClick={() => handleClick(conv._id, index)}
                        >
                          Watch
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="start"
                          alignItems="center"
                          marginTop="15px"
                        >
                          {conv.isProfane && (
                            <Warning
                              fontSize="large"
                              style={{ color: "#F00" }}
                            />
                          )}
                          {conv.isProfane && !conv.alertSeenByAdmin && (
                            <label
                              style={{ color: "#d62546", cursor: "pointer" }}
                            >
                              NEW
                            </label>
                          )}
                        </Box>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div className={classes.postDetails}>
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "20px",
                            color: "#d52a50",
                          }}
                        >
                          {conv.deleted ? "Deleted" : ""}
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                ))}

                {searchLoading && (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress color="secondary" />
                  </div>
                )}

                {conversations.length > 0 && (
                  <Movepoint
                    onEnter={() => {
                      showMoreConversations(
                        modelUserName,
                        fanUserName,
                        convProfanityOnly,
                        convStart,
                        convEnd,
                        false
                      );
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}

      {mode === "messages" && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Messages</h4>
                <p className={classes.cardCategoryWhite}>
                  Here are all the messages
                </p>
              </CardHeader>

              <CardBody>
                <div className={classes.tableHeader}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Date</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Sender</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Messages</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Amount</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Status</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Alert</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Deleted</label>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      className={classes.postDetails}
                    >
                      <label className={classes.textHeader}>Action</label>
                    </GridItem>
                  </GridContainer>
                </div>

                {messages.map((message, index) => (
                  <div key={message._id + index}>
                    <MessageDetails
                      message={message}
                      index={index}
                      deleteEnable={true}
                      senderName={message.sender.userName}
                      status={""}
                      onDelete={deleteMessage}
                    />
                    {message.mediaRequest &&
                      message.mediaRequest.historic.map((mediaRequest) => (
                        <MessageDetails
                          message={mediaRequest}
                          index={index}
                          deleteEnable={false}
                          senderName={mediaRequest.userName}
                          status={mediaRequest.status}
                        />
                      ))}
                    {message.mediaPrivateList?.[0] &&
                      message.mediaPrivateList.map((mediaPrivate) => (
                        <MessageDetails
                          message={mediaPrivate}
                          index={index}
                          deleteEnable={false}
                          senderName={mediaPrivate.senderName}
                          status={mediaPrivate.isLocked ? "Not payed" : "Payed"}
                        />
                      ))}
                    <Divider />
                  </div>
                ))}

                {searchLoading && (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress color="secondary" />
                  </div>
                )}
                {messages.length > 0 && (
                  <Movepoint
                    onEnter={() => {
                      showMore(
                        start,
                        nbMessagesByStep,
                        conversationId,
                        messageProfanityOnly,
                        false
                      );
                    }}
                    onLeave={() => {}}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}

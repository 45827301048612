import React, { useEffect, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  table: {
    minWidth: 650
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  today: {
    backgroundColor: "#f0e5c4"
  },
  daySelected: {
    backgroundColor: "#ddd"
  },
  colorPink: {
    color: "#d62546"
  },
  colorGreen: {
    color: "#228C22"
  },
  colorDark: {
    color: "#333"
  }
};

const useStyles = makeStyles(styles);

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const years = [];

let staringDate = 2020;
let date = new Date();
for (let year = staringDate; year <= date.getFullYear(); ++year) {
  years.push(year);
}

export default function BankDebits() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const [dayOpened, setDayOpened] = React.useState(-1);
  const [rows, setRows] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());

  useEffect(() => {
    getSubscribers();
  }, []);

  useEffect(() => {
    const authService = new AuthService();

    let date = new Date();
    date.setMonth(month);
    date.setYear(year);

    if (subscriptions.length) {
      // Get subscription debits
      authService.setUrl(context.apiUrl + context.subscribeHonoPath + "debits");
      authService.request(
        "get",
        {
          month: month + 1,
          year
        },
        response => {
          const { data } = response;

          let newRows = [];
          let now = new Date();

          let lastDayOfMonth = new Date(year, month + 1, 0).getDate();

          let date = new Date();
          date.setMonth(month);
          date.setYear(year);

          for (let i = lastDayOfMonth; i > 0; --i) {
            date.setDate(i);

            const subscriptionsThisDay = subscriptions.filter(s => {
              let current = new Date(s.date);
              let ref = new Date(date);
              ref.setDate(ref.getDate() - 1);
              return current < ref && current.getDate() === i;
            });

            let debitsCount = subscriptionsThisDay.length;

            let allCharges = data
              .filter(e => new Date(e.date).getDate() === i)
              .map(e => e.charges)
              .reduce((a, b) => a.concat(b), []);

            let debitsDone = allCharges.filter(e => e && !e.error).length;
            let error = allCharges.filter(e => e && e.error).length;

            // Make transfer data payload for the day i
            let row = {
              id: i,
              today:
                date.getDate() === now.getDate() &&
                date.getMonth() === now.getMonth() &&
                date.getFullYear() === now.getFullYear(),
              day: weekdays[date.getDay()] + " " + i,
              debitsCount,
              success: debitsDone,
              charges: allCharges,
              subscriptions: subscriptionsThisDay,
              error
            };
            newRows.push(row);
          }

          setRows(newRows);
        }
      );
    }
  }, [subscriptions, month, year]);

  const getSubscribers = async () => {
    let step = 1000;
    let start = 0;
    let end = step;

    let allSubscriptions = [];
    let newSubscriptions = null;

    while (!newSubscriptions || newSubscriptions.length === step) {
      newSubscriptions = await getSubscriptionsPart(start, end);
      allSubscriptions = allSubscriptions.concat(newSubscriptions);
      start = allSubscriptions.length;
      end = start + step;
    }

    setSubscriptions(allSubscriptions);
  }

  const getSubscriptionsPart = (start, end) => {
    return new Promise((resolve, reject) => {
      const authService = new AuthService();
      // Get subscriptions
      authService.setUrl(context.apiUrl + context.subscribeHonoPath + "all");
      authService.request(
        "get",
        {
          month: "all",
          year: "all",
          modelUserName: "",
          fanUserName: "",
          subscriptionType: "all",
          status: "active",
          start,
          end
        },
        response => {
          const { data } = response;
          resolve(data);
        }
      );
    });
  }

  const handleMonthChange = event => {
    setMonth(event.target.value);
  };

  const handleYearChange = event => {
    setYear(event.target.value);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Month</InputLabel>
        <Select onChange={handleMonthChange} value={month}>
          {monthNames.map((m, index) => (
            <MenuItem key={m} value={index}>
              {m}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Year</InputLabel>
        <Select onChange={handleYearChange} value={year}>
          {years.map(y => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Day</TableCell>
            <TableCell align="right">Debits to do</TableCell>
            <TableCell align="right">Sucess</TableCell>
            <TableCell align="right">Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <>
              <TableRow
                key={row.day}
                className={
                  row.id === dayOpened
                    ? classes.daySelected
                    : row.today
                    ? classes.today
                    : ""
                }
              >
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      setDayOpened(row.id !== dayOpened ? row.id : -1)
                    }
                  >
                    {dayOpened === row.id ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.day}
                </TableCell>
                <TableCell align="right">
                  {<b>{row.debitsCount ? row.debitsCount : "-"}</b>}
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    row.error === 0 ? classes.colorGreen : classes.colorDark
                  }
                >
                  <b>{row.success ? row.success : "-"}</b>
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    row.error > 0 ? classes.colorPink : classes.colorDark
                  }
                >
                  <b>{row.error ? row.error : "-"}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse
                    in={dayOpened === row.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    {row.subscriptions && row.subscriptions.length > 0 && (
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Subscriptions
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Fan</TableCell>
                              <TableCell>Model</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.subscriptions.map(subscription => (
                              <TableRow key={subscription.date}>
                                <TableCell>{subscription.fanName}</TableCell>
                                <TableCell>{subscription.modelName}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    )}

                    {row.charges && row.charges.length > 0 && (
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Transactions
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Transaction ID</TableCell>
                              <TableCell>Fan</TableCell>
                              <TableCell>Model</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Charge ID</TableCell>
                              <TableCell>Error</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.charges.map(charge => (
                              <>
                                {
                                  charge &&
                                  <TableRow key={charge.date}>
                                    <TableCell>{charge._id}</TableCell>
                                    <TableCell>{charge.fanName}</TableCell>
                                    <TableCell>{charge.modelName}</TableCell>
                                    <TableCell
                                      className={
                                        charge.error
                                          ? classes.colorPink
                                          : classes.colorGreen
                                      }
                                    >
                                      {charge.error ? "KO" : "OK"}
                                    </TableCell>
                                    <TableCell>{charge.chargeId}</TableCell>
                                    <TableCell>{charge.error}</TableCell>
                                  </TableRow>
                                }
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    )}
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
